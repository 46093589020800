import React from 'react';
import {Button} from 'reactstrap';
import {Loader} from "react-loaders";
import BlockUi from "react-block-ui";


export default class NewStudent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };

        this.delete = this.delete.bind(this);
    }

    delete(){
        this.setState({loading: true});

        fetch('/api/newsletter/manage/delete', {
            credentials: 'same-origin',
            method: 'post',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({
                email: this.props.email
            })
        })
            .then(res => res.json())
            .then(resJSON=>{

                this.setState({loading: false});

                if (resJSON.success){

                    this.props.updateList(this.props.index);

                    this.props.notify('success', <span><strong>Success!</strong> Email has been removed from your mailing list.</span>)
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                }else {
                    this.props.notify('error', <span><strong>Failed!</strong> Please try again!</span>)

                }

            })
    }

    render() {


        return (
            <BlockUi tag="div" blocking={this.state.loading} style={{overflow:'hidden'}}
                     loader={<Loader color="#ffffff" active type={'ball-clip-rotate'}/>}>
            <Button color={'danger'} size={'sm'} onClick={this.delete}>Delete</Button>
            </BlockUi>
        );
    }
}
