import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';

import MetisMenu from 'react-metismenu';
import RouterLink from './RouterLink';

import {MainNav} from './NavItems';

class Nav extends Component {

    componentDidMount() {

        this.props.history.listen((location, action) => {
            // noinspection JSDeprecatedSymbols
            if (this.refs.menu)
                this.refs.menu.changeActiveLinkFromLocation();

        });
    }


    render() {

        return (
            <Fragment><h5 className="app-sidebar__heading" style={{marginTop:30}}> </h5>
                <MetisMenu ref={"menu"} content={MainNav} LinkComponent={RouterLink} activeLinkFromLocation className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>
            </Fragment>
        );
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }
}

export default withRouter(Nav);
