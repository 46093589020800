import React, {Component, Fragment} from 'react';
import {Card, CardHeader, CardBody, Button,} from "reactstrap";

import ReactTable from "react-table";
import GalleryEditor from "./Components/GalleryEditor";
// import NewGallery from "./Components/NewGallery";
import NewGallery from "./Components/NewGalleryPage";



export default class List extends Component {

    constructor(props){
        super(props);
        this.state = {
            pageList: [],
            loading: true,
            pages: 0,
        };

        this.modifyData = this.modifyData.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    componentWillMount() {


    }

    modifyData(id, images){

        this.state.pageList.forEach(gallery=>{
            if (gallery.id === id){
                gallery['gallery_data']['gallery'] = images;
            }
        });
        this.setState({pageList: this.state.pageList});
    }

    fetchData() {
        const url = `/api/website/wordpress/galleries?page=${this.state.table_state.page}&limit=${this.state.table_state.pageSize}`;

        fetch(url, {credentials: 'same-origin'})
            .then(res=>res.json())
            .then(resJSON=>{

                if (resJSON.success) {
                    this.setState({
                        pageList: resJSON.galleries,
                        pages: resJSON.total_pages,
                        loading: false
                    });
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                }
            })
    }

    render() {
        return (
            <Fragment>
                <Card>
                    <CardHeader style={{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>

                                    <span>
                                        Manage Galleries
                                    </span>

                        <div>
                            <Button id={'newGallery'}
                                    color="primary" size={'sm'}
                                    onClick={()=>{

                                        this.props.history.push('/website/galleries/new');

                                    }}
                            >
                                New Gallery
                            </Button>

                            &nbsp;
                        </div>
                    </CardHeader>


                    <CardBody>

                        <ReactTable
                            data={this.state.pageList}
                            columns={[
                                {
                                    Header: "Title",
                                    accessor: row => row.post_title,
                                    id: 'title'
                                },
                                {
                                    Header: "Photos",
                                    accessor: row => row.gallery_data.gallery.length,
                                    id: 'photos'
                                },
                                {
                                    Header: "Created",
                                    accessor: row => new Date(row.post_date).toLocaleDateString(),
                                    id: 'date'
                                }, {
                                    Header: "Actions",
                                    Cell: ({original})=>{
                                        return (
                                            <div>
                                                {/*<GalleryEditor*/}
                                                {/*    images={original.gallery_data.gallery}*/}
                                                {/*    galleryID={original.id}*/}
                                                {/*    modifyData={this.modifyData}*/}
                                                {/*    refresh={this.fetchData}*/}
                                                {/*/>*/}

                                                <Button id={'editButton' + original.id}
                                                        color="primary" size={'sm'}
                                                        onClick={()=>{

                                                            this.props.history.push('/website/galleries/edit', {
                                                                images: original.gallery_data.gallery,
                                                                galleryID: original.id,
                                                            })

                                                        }}
                                                        style={{width:70}}
                                                >Edit
                                                </Button>

                                                &nbsp;
                                                {/*<a target="_blank" className={'btn btn-sm btn-secondary'} href={original.link}>View</a>*/}
                                            </div>
                                        )
                                    },
                                    id: 'actions'
                                }
                            ]}
                            defaultPageSize={10}
                            filterable={false}
                            loading={this.state.loading}
                            loadingText={'Loading pages...'}
                            className="-striped -highlight"
                            manual
                            pages={this.state.pages}
                            onFetchData={(state, instance) => {
                                this.setState({loading: true, table_state: state}, ()=>{
                                    this.fetchData();
                                });





                         /*       fetch('/api/website/galleries/' + (parseInt(state.page)+1), {credentials: 'same-origin'})
                                    .then(res=>res.json())
                                    .then(resJSON=>{
                                        this.setState({
                                            pageList: resJSON.galleries,
                                            pages: resJSON.headers['x-wp-totalpages'],
                                            loading: false
                                        });
                                    })*/
                            }}
                        />
                    </CardBody>
                </Card>
            </Fragment>
        )
    }
}
