import React, {Component, Fragment} from 'react';

import {
    Row, Col} from 'reactstrap';

import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';

function boxMullerRandom() {
    let phase = false,
        x1, x2, w;

    return (function () {

        if (phase === !phase) {
            do {
                x1 = 2.0 * Math.random() - 1.0;
                x2 = 2.0 * Math.random() - 1.0;
                w = x1 * x1 + x2 * x2;
            } while (w >= 1.0);

            w = Math.sqrt((-2.0 * Math.log(w)) / w);
            return x1 * w;
        } else {
            return x2 * w;
        }
    })();
}

function randomData(n = 30) {
    return Array.apply(0, Array(n)).map(boxMullerRandom);
}

class DrawerBigExample extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            dropdownOpen: false,
            notifications: []
        };
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    componentDidMount() {

        fetch('/api/support/changelog', {credentials: 'same-origin'})
            .then(res=>res.json())
            .then(resJSON=>{
                if (resJSON.success) {
                    this.setState({
                        notifications: resJSON.data,
                        loading: false
                    });
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                }
            });
    }

    render() {

        return (
            <Fragment>
                <h3 className="drawer-heading">
                    Servers Status
                </h3>
                <div className="drawer-section">
                    <Row>
                        <Col>
                            <div className="progress-box">
                                <h4>Website Status</h4>
                                <i className="pe-7s-check pe-5x icon-gradient bg-tempting-azure"></i>

                                <p>
                                    <h7>No faults</h7>
                                </p>
                            </div>
                        </Col>
                        <Col>
                            <div className="progress-box">
                                <h4>App Status</h4>
                                <i className="pe-7s-check pe-5x icon-gradient bg-tempting-azure"></i>

                                <p>
                                    <h7>No faults</h7>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <h3 className="drawer-heading">
                    Notifications
                </h3>
                <div className="drawer-section">
                    <div className="notifications-box">
                        <VerticalTimeline animate={false} layout="1-column"
                                          className="vertical-time-simple vertical-without-time">


                            {this.state.notifications.map((notification)=>(
                                <VerticalTimelineElement
                                    className="vertical-timeline-item dot-info"
                                >

                                    <a target="_blank" rel="noopener noreferrer" href={`https://support.kiwischools.co.nz/en/announcements/article/${notification.slug}`}>
                                        <h4 className="timeline-title">{notification.title}</h4>
                                    </a>
                                    <p dangerouslySetInnerHTML={{ __html: notification.text }}></p>
                                </VerticalTimelineElement>
                            ))}

                        </VerticalTimeline>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default DrawerBigExample;
