import React, {Component, Fragment} from 'react';
import {Card, CardHeader, CardBody,Button, } from "reactstrap";

import ReactTable from "react-table";
import StaffEditor from "./Components/StaffEditor";

import { toast } from 'react-toastify';


export default class StaffList extends Component {

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            list: [],
            enabled: true
        };

        this.updateStaffList = this.updateStaffList.bind(this);
        this.deleteStaff = this.deleteStaff.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    fetchData() {
        fetch('/api/app/manage/stafflist', {credentials: 'same-origin'})
            .then(res=>res.json())
            .then(resJSON=>{

                if (resJSON.success) {
                    this.setState({list: resJSON.staff, loading:false});

                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.setState({list: [], loading:false, enabled: false});
                    this.notify('error', <span><strong>Failed!</strong> Your staff list failed to load, please try again.</span>)

                }

            });
    }

    componentWillMount() {

       this.fetchData()


    }

    notify(type, text) {
        toast[type](text);

    }
    updateStaffList(data) {

        this.state.list.push({
            'Name': data['name'],
            'Class': data['class'],
            'Role': data['role'],
            'Email': data['email'],
            'Image URL': data['imageURL'],
            'Biography': data['biography'],
            'Section': data['section'],
        });



        this.setState({list: this.state.list})


    }

    deleteStaff(index) {

        fetch('/api/app/manage/stafflist/delete', {
            credentials: 'same-origin',
            method: 'post',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({index: index})
        })
            .then(res => res.json())
            .then(resJSON=>{

                if (resJSON.success){

                    this.state.list.splice(index, 1);
                    this.setState({list: this.state.list});


                    this.notify('success', <span><strong>Success!</strong> Your staff list has been updated.</span>)
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.notify('danger', <span><strong>Failed!</strong> Please try again!</span>)

                }

            })



    }

    moveStaff(index, direction){

        let list = this.state.list;


        fetch('/api/app/manage/stafflist/move', {
            credentials: 'same-origin',
            method: 'post',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({index: index, direction:direction})
        })
            .then(res => res.json())
            .then(resJSON=>{

                if (resJSON.success){


                    if (direction === 'up' && index !== 0 ){
                        const row = list.splice(index, 1)[0];
                        list.splice(index-1, 0, row);
                    }
                    if (direction === 'down' && index !== list.length - 1){
                        const row = list.splice(index, 1)[0];
                        list.splice(index+1, 0, row);
                    }

                    this.setState({list: list});

                    this.notify('success', <span><strong>Success!</strong> Your staff list has been updated.</span>)
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.notify('danger', <span><strong>Failed!</strong> Please try again!</span>)

                }

            })


    }

    render() {
        return (
            <Fragment>
                            <Card>
                                <CardHeader style={{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>

                                    <span>

                                    Manage Staff List
                                    </span>

                                    {this.state.enabled?

                                    <div>

                                        <Button className='btn-sm' onClick={()=>{
                                            this.props.history.push('managestaff/edit', {
                                                data: {
                                                    'Name': '',
                                                    'Class': '',
                                                    'Role': '',
                                                    'Email': '',
                                                    'Image URL': '',
                                                    'Biography': this.state.list[0]?this.state.list[0]['Biography']?' ':'':'',
                                                    'Section': '',
                                                },
                                                label: 'New Staff',
                                                new: true
                                            })
                                        }}>New Staff</Button>
                                    </div>:null}
                                </CardHeader>


                                <CardBody>
                                    {this.state.enabled ?


                                        <ReactTable
                                            data={this.state.list}
                                            columns={[
                                                {
                                                    Header: "Name",
                                                    accessor: "Name"
                                                },
                                                // {
                                                //     Header: "Class",
                                                //     accessor: 'Class'
                                                // },
                                                // {
                                                //     Header: "Role",
                                                //     accessor: 'Role'
                                                // },
                                                {
                                                    Header: "Email",
                                                    accessor: 'Email'
                                                },
                                                // {
                                                //     Header: "Image URL",
                                                //     accessor: 'Image URL'
                                                // },
                                                {
                                                    Header: "Manage",
                                                    accessor: 'manage',
                                                    filterable: false,
                                                    Cell: props => (
                                                        <div>

                                                            <Button className='btn-sm' onClick={()=>{
                                                                this.moveStaff(props.index, "up")
                                                            }}><i className="lnr lnr-chevron-up"/></Button>
                                                            &nbsp;
                                                            <Button className='btn-sm' onClick={()=>{
                                                                this.moveStaff(props.index, "down")
                                                            }}><i className="lnr lnr-chevron-down"/></Button>

                                                            &nbsp;

                                                            <Button className='btn-sm' onClick={()=>{
                                                                    this.props.history.push('managestaff/edit', {
                                                                            data: props.original,
                                                                            index: props.index,
                                                                            label: 'Edit Details'
                                                                    })
                                                            }}>Edit Details</Button>


                                                            &nbsp;

                                                            <Button color={'danger'} className='btn-sm' onClick={() => {
                                                                this.deleteStaff(props.index)
                                                            }}>Delete</Button>

                                                        </div>
                                                    )
                                                }
                                            ]}
                                            defaultPageSize={10}
                                            filterable={true}
                                            loading={this.state.loading}
                                            loadingText={'Loading...'}
                                            className="-striped -highlight"
                                        />
                                        :
                                        <span>
                                            Staff list management has not been enabled for your domain yet. Please contact your KiwiSchools account manager or submit a support ticket if you would like to be prioritised for this upgrade.
                                        </span>
                                    }
                                </CardBody>
                            </Card>
            </Fragment>
        )
    }
}
