import React, {Fragment} from 'react';

import {
    DropdownToggle, DropdownMenu,
    Nav, Col, Row, Button, NavItem, NavLink,
    UncontrolledButtonDropdown
} from 'reactstrap';

import {
    toast,
    Bounce
} from 'react-toastify';


import {
    faAngleDown,

} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg';

class UserBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };
    }

    notify2 = () => this.toastId = toast("You don't have any new items in your calendar for today! Go out and play!", {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'bottom-center',
        type: 'success'
    });




    render() {

        return (
            <Fragment>
                <div className="header-btn-lg pr-0">
                    <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color="link" className="p-0">
                                        <img width={42} className="rounded-circle" src={'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png'} alt=""/>
                                        <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown}/>
                                    </DropdownToggle>
                                    <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                                        <div className="dropdown-menu-header">
                                            <div className="dropdown-menu-header-inner bg-info">
                                                <div className="menu-header-image opacity-2"
                                                     style={{
                                                         backgroundImage: 'url(' + city3 + ')'
                                                     }}
                                                />
                                                <div className="menu-header-content text-left">
                                                    <div className="widget-content p-0">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left mr-3">
                                                                <img width={42} className="rounded-circle" src={'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png'}
                                                                     alt=""/>
                                                            </div>
                                                            <div className="widget-content-left">
                                                                <div className="widget-heading">
                                                                    {this.props.user.name}
                                                                </div>
                                                                <div className="widget-subheading opacity-8">
                                                                    {this.props.user.domain}
                                                                </div>
                                                            </div>
                                                            <div className="widget-content-right mr-2">
                                                                <Button className="btn-pill btn-shadow btn-shine"
                                                                        onClick={this.props.logout}
                                                                        color="focus">
                                                                    Logout
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                                <Nav vertical>
                                                    <NavItem className="nav-item-header">
                                                        My Domain
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink href="javascript:void(0);">
                                                            Manage Users
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink href="javascript:void(0);">
                                                            My Products
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                        </div>
                                        {/*<Nav vertical>*/}
                                        {/*    <NavItem className="nav-item-divider mb-0"/>*/}
                                        {/*</Nav>*/}
                                        {/*<div className="grid-menu grid-menu-2col">*/}
                                        {/*    <Row className="no-gutters">*/}
                                        {/*        <Col sm="6">*/}
                                        {/*            <Button*/}
                                        {/*                className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"*/}
                                        {/*                outline color="warning">*/}
                                        {/*                <i className="pe-7s-chat icon-gradient bg-amy-crisp btn-icon-wrapper mb-2"> </i>*/}
                                        {/*                Contact Us*/}
                                        {/*            </Button>*/}
                                        {/*        </Col>*/}
                                        {/*        <Col sm="6">*/}
                                        {/*            <Button*/}
                                        {/*                className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"*/}
                                        {/*                outline color="danger">*/}
                                        {/*                <i className="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2"> </i>*/}
                                        {/*                <b>Support Tickets</b>*/}
                                        {/*            </Button>*/}
                                        {/*        </Col>*/}
                                        {/*    </Row>*/}
                                        {/*</div>*/}
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </div>
                            <div className="widget-content-left  ml-3 header-user-info">
                                <div className="widget-heading">
                                    {this.props.user.name}
                                </div>
                                <div className="widget-subheading">
                                    {this.props.user.domain}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default UserBox;
