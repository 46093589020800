import React from 'react';
import {Row, Col, Card, CardBody} from 'reactstrap';


export default class InactiveProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {


        return (
            <div>

                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <div style={{display:'flex', justifyContent: 'center', alignItems: 'center', padding: 20}}>
                                    <div style={{textAlign: 'center'}}>
                                    <h4>This KiwiSchools product has not been activated on your account!</h4>
                                    <p>To enquire about our products, please contact your KiwiSchools account manager.</p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}
