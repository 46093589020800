import React from 'react';
import {useDrag} from 'react-dnd'
import {toast} from "react-toastify";
import {blocks} from "./BlockManager";

export default function Block({isDragging, label, icon, type}) {

    const [{ opacity }, dragRef] = useDrag({
        item: { type: 'element', element: type },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {

                let data =  blocks[item.element].data;

                if (item.element === 'columns'){
                    data = {elements: [[],[],[]], settings:[]};
                }

                if (!(dropResult.isColumn === true && item.element === 'columns')){

                    dropResult.addElement({
                        type: item.element,
                        element: blocks[item.element].element
                    }, dropResult.index, data, dropResult.column);
                } else {
                    toast['error']("Error! You cannot have a column within a column!");
                }


            }
        },
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.5 : 1,
        }),
    });

        return (
            <div
                ref={dragRef}
                style={{
                    border:'solid',
                    borderWidth: '0.05em',
                    borderColor: 'lightgray',
                    borderRadius: 5,
                    width:100,
                    height:100,
                    textAlign: 'center',
                    padding:10,
                    display:'grid'

            }}>
                <div style={{margin: 'auto'}}>
                    <i className={icon} style={{color: 'lightgray', fontSize:30,display: 'flex',justifyContent: 'center'}}> </i>
                    <div style={{marginTop:5}}>{label}</div>
                </div>

            </div>
        )
}

