import React, {Component, Fragment} from 'react';
import {Container, Row, Col, Card, CardHeader, CardBody,} from "reactstrap";

export default class UploadStudents extends Component {

    render() {
        return (
            <Fragment>
                <Container>
                    <Row className={'mb-3'}>
                        <Col>
                            <Card>
                                <CardHeader>
                                    New Student and Leavers List Upload
                                </CardHeader>


                                <CardBody>

                                    Student uploads are currently handled by our old dashboard. Please access it by clicking this link:
                                    &nbsp;<a href={"https://dashboard.kiwischools.co.nz"}>https://dashboard.kiwischools.co.nz</a>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
