import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import BlockUi from "react-block-ui";
import {Loader} from "react-loaders";


export default class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            data: this.props.data,
            title: this.props.title,
            loading: false,
            images: this.props.images,
            uploaded: [],
            updating: false
        };

        this.toggle = this.toggle.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        }, ()=>{
            if (!this.state.modal){
                this.props.modifyData(this.props.galleryID, this.props.images);
            }
        });


    }

    onSave(){


        this.setState({updating:true});
        fetch('/api/website/gallery/update',{
            credentials: 'same-origin',
            method: 'post',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({uploaded: this.state.uploaded, galleryID: this.props.galleryID})
        })
            .then(res => res.json())
            .then(resJSON =>{
                if(resJSON.success) {
                    this.setState({updating: false});
                    this.props.refresh();
                    this.toggle();

                }else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                }
            })


        // this.setState({loading: true});
        //
        // let url = '/website/pages/update';
        // if (this.props.isPost){
        //     url = '/website/posts/update';
        // }
        // fetch(url,{
        //     credentials: 'same-origin',
        //     method: 'post',
        //     headers: {
        //         'Content-Type': "application/json"
        //     },
        //     body: JSON.stringify({id: this.props.id, title: this.state.title, content: this.state.data})
        // })
        //     .then(res => res.json())
        //     .then(resJSON =>{
        //         this.setState({loading: false});
        //
        //         if (resJSON.success){
        //             this.toggle();
        //             toast['success']("Success! Post Updated.");
        //         } else {
        //             toast['error']("Error! Please try again.");
        //
        //         }
        //     })
    }

    onDelete(index){
        this.setState({loading: true});
        fetch('/api/website/gallery/remove',{
                credentials: 'same-origin',
                method: 'post',
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({id: this.state.images[index].id, galleryID: this.props.galleryID})
            })
                .then(res => res.json())
                .then(resJSON =>{
                    if(resJSON.success) {
                        this.state.images.splice(index, 1);
                        this.setState({loading: false, images: this.state.images});
                    }else if (resJSON['isAuthFailed']){
                        if (process.env.NODE_ENV === 'development'){
                            window.location.href = 'http://localhost:3001/api/login';
                        } else {
                            window.location.href = 'https://central.kiwischools.co.nz/api/login';
                        }
                    }
                })
    }


    getUploadParams = ({ file, meta }) => {
            const body = new FormData();
            body.append('file', file);
            body.append('galleryID', this.props.galleryID);
            return { url: '/api/website/gallery/upload', body }
        };

    handleChangeStatus = ({ meta, remove, xhr }, status) => {
            if (status === 'headers_received') {
                this.state.images.push({
                    src: meta.previewUrl,
                    title: meta.name
                });

                this.setState({images:this.state.images});
            }
            if (status === 'done'){
                remove();

                let res = JSON.parse(xhr.response);

                this.state.uploaded.push(res['id']);
            }
        };

    render() {

        return (
            <span className="d-inline-block">

                <Button id={'editButton' + this.props.id}
                        color="primary" size={'sm'}
                        onClick={this.toggle}
                        style={{width:70}}
                >Edit
                </Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="xl">
                    <ModalHeader toggle={this.toggle}>New Gallery</ModalHeader>
                    <ModalBody>
                        <BlockUi tag="div" blocking={this.state.loading}  style={{overflow:'hidden'}} loader={<Loader color="#ffffff" active type={'ball-clip-rotate'}/>}>

                            <Dropzone
                                getUploadParams={this.getUploadParams}
                                onChangeStatus={this.handleChangeStatus}
                                accept="image/*"
                                inputContent={(files, extra) => (extra.reject ? 'Image files only' : 'Drag and drop images, or click to upload.')}
                                submitButtonDisabled={true}
                                styles={{
                                    dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
                                    inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
                                }}
                            />
                        <div>
                        {this.state.images.map((image, index) => (
                            <div style={{position:'relative', height: 150, width: 150, display:'inline-table', padding:5}}>
                                <Button
                                    color={'danger'}
                                    style={{position: 'absolute', top:5, right: 5, padding:5}}
                                    onClick={()=>{this.onDelete(index)}}
                                ><i className="lnr lnr-cross"/></Button>
                                <img
                                    src={image.src.indexOf('blob') > -1 ? image.src:image.src.replace(/-\d+x\d+/, '').replace('.' + image.src.split('.')[image.src.split('.').length-1], '-150x150.' + image.src.split('.')[image.src.split('.').length-1])}
                                    alt={image.title}
                                    style={{height:150,width:150, objectFit:'cover'}}
                                />
                            </div>


                            ))
                        }
                        </div>
                        </BlockUi>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="link" onClick={this.toggle}>Cancel</Button>
                        <BlockUi tag="div" blocking={this.state.updating}  style={{overflow:'hidden'}} loader={<Loader color="#ffffff" active type={'ball-clip-rotate'}/>}>
                            <Button color="primary" onClick={this.onSave}>Save</Button>
                        </BlockUi>
                    </ModalFooter>
                </Modal>
            </span>
        );
    }
}
