const steps = [
    {
        selector: "#menu-account",
        content: "Open 'Account' tab",
    },
    {
        selector: "#menu-account-users",
        content: "Choose 'Manage Users",

    },
    {
        selector: '#authorise-user-row',
        content: 'Fill in email address and name of user then click Authorise. Both fields must be filled in. The user will instantly have access to KiwiSchools Central.'
    },
];

export default steps;
