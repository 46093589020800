import React, {Component, Fragment} from 'react';
import {
    Row, Col,
    Card, CardBody} from 'reactstrap';

import Toolbox from "./Components/Toolbox";
import HTML5Backend from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import Preview from "./Components/Preview";
import Text from "./Components/Blocks/Text";
import Image from "./Components/Blocks/Image";
import Columns from "./Components/Blocks/Columns";
import Container from "./Components/Blocks/Container"
import {toast} from "react-toastify";
import Divider from "./Components/Blocks/Divider";
import Attachment from "./Components/Blocks/Attachment";
import Spacer from "./Components/Blocks/Spacer";

import Loader from 'react-loaders'
import Button from "reactstrap/es/Button";

export default class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            elements: [[],[],[]],
            settingsElements: {
                elements: [[],[],[]],
                settings:[],
                index:0,
                column:0,
                setElementSettings: this.setElementSettings,
                updateElementData: this.updateElementData
            },
            settings: [],
            activeTab: '1',
            id: 0,
            isTop: true,
            loading: true,
            margins: [],
            templates: []

        };

        this.addElement = this.addElement.bind(this);
        this.addMovedElement = this.addMovedElement.bind(this);
        this.removeElement = this.removeElement.bind(this);
        this.moveElement = this.moveElement.bind(this);
        this.updateElement = this.updateElement.bind(this);
        this.setStyleSettings = this.setStyleSettings.bind(this);
        this.setTab = this.setTab.bind(this);
        this.setElementSettings = this.setElementSettings.bind(this);
        this.updateElementData = this.updateElementData.bind(this);
        this.setElementsForToolbox = this.setElementsForToolbox.bind(this);
        this.getID = this.getID.bind(this);
        this.save = this.save.bind(this);
        this.prepare = this.prepare.bind(this);
        this.setElements = this.setElements.bind(this);
        this.load = this.load.bind(this);
        this.loadTemplate = this.loadTemplate.bind(this);

        this.previewHeight = React.createRef()
    }

    addElement(type, index, data, column){
        type["settings"]= {index: index};
        type["data"]= data;
        this.state.elements[column].forEach((item)=>{
            if (item.settings.index >= index){
                item.settings.index++;

            }
        });

        this.state.elements[column].splice(index, 0, type);


        this.setState({ elements:  this.state.elements});

        this.save();

    }
    addMovedElement(item, index, column){
        this.state.elements[column].forEach((item)=>{
            if (item.settings.index >= index){
                item.settings.index++;
            }
        });
        this.state.elements[column].splice(index, 0, item);


        this.setState({ elements:  this.state.elements});


    }

    removeElement(index, column){

        let removed = this.state.elements[column].splice(index, 1);

        this.notify('success', <div>Deleted Section. <a href="#" onClick={()=>{
            this.addMovedElement(removed[0], index, column);
        }}>Click here to undo.</a></div>, 20000);

        this.state.elements[column].forEach((item)=>{
            if (item.settings.index >= index){
                item.settings.index--;
            }
        });
        this.setState({ elements:  this.state.elements});

        return removed

    }

    moveElement(from, to, column){
        this.state.elements[column].splice(to, 0, this.state.elements[column].splice(from, 1)[0]);
        this.setState({ elements:  this.state.elements});
    }

    updateElement(index, data, column){
        this.state.elements[column][index].data = data;
        this.setState({ elements:  this.state.elements});

    }

    setStyleSettings(settings, index, column){


        settings['index']= index;
        settings['column']= column;
        this.setState({settings:settings,
            settingsElements: {
                elements: this.state.elements,
                settings: settings,
                index: index,
                column: column,
                setElementSettings: this.setElementSettings,
                updateElementData: this.updateElementData
            }});
    }

    setElementSettings(settings, column){

        this.state.elements[column][this.state.settings.index]['settings'] = {...this.state.elements[column][this.state.settings.index]['settings'], ...settings};
        this.setState({elements: this.state.elements});
    }

    updateElementData(){
        this.setState({elements: this.state.elements});
    }

    setTab(tab){
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    setElementsForToolbox(elements, settings, index, column, setElementSettings, updateElementData){
        this.setState({
            settingsElements: {
                elements: elements,
                settings: settings,
                index: index,
                column: column,
                setElementSettings: setElementSettings,
                updateElementData: updateElementData
            }
        });
    }

    getID(){
        this.setState({id: this.state.id+1});
        return this.state.id;
    }

    prepare(){

        function buildBlocks(elements){

            let elementArray = [[],[],[]];

            elements.map((column, index)=>{

                column.map(element=> {

                    if (element.type === 'columns') {

                        elementArray[index].push({
                            type: element.type,
                            data: {elements: buildBlocks(element.data.elements), settings: element.data.settings},
                            settings: element.settings
                        })


                    } else {
                        elementArray[index].push({
                            type: element.type,
                            data: element.data,
                            settings: element.settings

                        })
                    }


                });

            });

            return elementArray;
        }

        return buildBlocks(this.state.elements);



    }

    save(hideNotify) {
        fetch('/api/newsletter/send/builder/save', {
            credentials: 'same-origin',
                method: 'post',
                headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({build: this.prepare(), id:document.getElementById("templateSelect").value})
        }).then(()=>{
            if (!hideNotify) {
                this.notify('success', "Newsletter saved successfully!")
            }
        })
    }

    setElements(elements){

        const blocks = {
            text: <Container element={<Text/>}/>,
            image: <Container element={<Image/>}/>,
            divider: <Container element={<Divider/>}/>,
            spacer: <Container element={<Spacer/>}/>,
            columns: <Container element={<Columns/>}/>,
            attachment: <Container element={<Attachment/>}/>,
        };

        elements.map(column=>{
            column.map(item=>{


                if (item.type === 'columns'){
                    item.data.elements = this.setElements(item.data.elements);
                }

                item.element = blocks[item.type];

            })


        });

        return elements;
    }

    load() {
        fetch('/api/newsletter/send/builder/load', {credentials: 'same-origin'})
            .then(res => res.json())
            .then(resJSON => {

                if (resJSON.build){
                    this.setState({elements: this.setElements(resJSON.build), templates: resJSON.templates});
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                }

                this.setState({loading: false})

            })
    }

    loadTemplate() {

        fetch('/api/newsletter/send/builder/load/' + document.getElementById("templateSelect").value, {credentials: 'same-origin'})
            .then(res => res.json())
            .then(resJSON => {
                console.log(resJSON.build.build)
                if (resJSON.build){
                    this.setState({elements: this.setElements(resJSON.build.build)});
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                }

                this.setState({loading: false})

            })
    }

    notify(type, text, autoClose) {
        toast[type](text, {autoClose: autoClose?autoClose:8000});

    }

    componentDidMount() {
        this.load();

    }

    render() {

        return (
            <Fragment>

                <DndProvider backend={HTML5Backend}>


                <Row>
                        <Col md={8}>
                            <Card className="main-card mb-3">
                                <CardBody style={{width: '100%', overflow: 'auto'}}>
                                    {/*<CardTitle>Newsletter Builder</CardTitle>*/}
                                    <div
                                        ref={this.previewHeight}
                                    >


                                        {this.state.loading?<Loader active={true} type="ball-pulse"/>:
                                            <div style={{ paddingLeft:10, paddingRight:10}}>

                                                <div style={{display:'flex', justifyContent:'center', minWidth: 600}}
                                                     ref={ (previewElement) => this.previewElement = previewElement}
                                                >

                                                    <div style={{width: 600, position: 'absolute'}}>


                                                        {this.state.margins.map((marginTop)=>(
                                                            <div style={{marginTop: marginTop, width:'100%', height: 1, zIndex: 999999, position: 'absolute', backgroundColor: 'red'}}/>
                                                        ))}

                                                        {/*<div style={{width:'100%', height: 1, marginTop: 1090, zIndex: 999999, position: 'absolute', backgroundColor: 'red'}}/>*/}
                                                        {/*<div style={{width:'100%', height: 1, marginTop: 1090*2, zIndex: 999999, position: 'absolute', backgroundColor: 'red'}}/>*/}

                                                    </div>

                                                    <Preview elements={this.state.elements[0]}
                                                             setStyleSettings={this.setStyleSettings}
                                                             updateElement={this.updateElement}
                                                             updateElementData={this.updateElementData}
                                                             setTab={this.setTab}
                                                             removeElement={this.removeElement}
                                                             moveElement={this.moveElement}
                                                             addElement={this.addElement}
                                                             addMovedElement={this.addMovedElement}
                                                             column={0}
                                                             setElementsForToolbox={this.setElementsForToolbox}
                                                             getID={this.getID}
                                                             isTop={this.state.isTop}
                                                    />
                                                </div>


                                            </div>

                                    }
                                    </div>
                                </CardBody>
                            </Card>

                        </Col>
                        <Col sm={4} style={{
                            position: 'fixed',
                            right: 0,
                            width: '28%',
                        }}>

                            <Toolbox elements={this.state.settingsElements.elements}
                                     settings={this.state.settingsElements.settings}
                                     activeTab={this.state.activeTab}
                                     setTab={this.setTab}
                                     setElementSettings={this.state.settingsElements.setElementSettings}
                                     updateElementData={this.state.settingsElements.updateElementData}
                                     index={this.state.settingsElements.index}
                                     column={this.state.settingsElements.column}
                                     getID={this.getID}
                                     prepare={this.prepare}
                                     save={this.save}
                                     notify={this.notify}
                                     history={this.props.history}
                                     templates={this.state.templates}
                                     loadTemplate={this.loadTemplate}
                                     load={this.load}

                                     style={{position: 'relative'}}

                            />


                        </Col>
                    </Row>
                </DndProvider>

            </Fragment>
        )
    }
}

