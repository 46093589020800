import React from 'react'

// import CKEditor from '@ckeditor/ckeditor5-react';
// import InlineEditor from '@ckeditor/ckeditor5-build-inline';

import { Editor } from '@tinymce/tinymce-react';


export default class Text extends React.Component{

    constructor(props){
        super(props);

        this.state={};

    }

    render() {

        return(


            <div onClick={(e)=>{
                     if (e.target.name === 'delete') return;
                     this.props.setStyleSettings([
                         {
                             type: 'color',
                             label: 'Background Colour',
                             id: 'backgroundColor',
                             value: '#000000'
                         },{
                             type: 'number',
                             label: 'Padding',
                             id: 'padding',
                             value: 0
                         },{
                             type: 'borderImage',
                             label: 'Border',
                             id: 'borderImage',
                             value: 0
                         },{
                             type: 'numberPX',
                             label: 'Border Radius',
                             id: 'borderRadius',
                             value: 0
                         }
                     ], this.props.index, this.props.column);
                     this.props.setTab('2');


                 }}
            >

                    <div>

                        {this.props.elements[this.props.index].data===''?
                            <div style={{position: 'absolute', paddingTop: '1em'}}> Click here to edit text.</div>
                            : null}
                        <Editor

                                onEditorChange={(content, editor)=>{
                                    this.props.updateElement(this.props.index, content, this.props.column)
                                }}
                                inline={true}
                                value={this.props.elements[this.props.index].data}
                                init={{
                                    'toolbar': 'fontselect fontsizeselect forecolor backcolor | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist | table link image | undo redo',
                                    'plugins': 'lists link image paste table',
                                    table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',

                                    menubar:false,
                                    contextmenu: false,
                                    images_upload_url: '/api/website/media/upload/builder',
                                    image_class_list: [
                                        {title: 'Responsive', value: 'img-responsive'}
                                    ],
                                    relative_urls : false,
                                    remove_script_host : false,
                                    paste_auto_cleanup_on_paste : false
                                    // placeholder: "Click here to edit text.",
                                }}
                        />

                        <span style={{clear: 'left', display: 'block'}} />
                        <span style={{clear: 'right', display: 'block'}} />
                </div>


            </div>
            )
    }

}

