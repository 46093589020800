import React, {Component, Fragment} from 'react';
import {Card, CardHeader, CardBody,} from "reactstrap";

import tours from '../../Tutorials';

export default class Help extends Component {

    constructor(props){
        super(props);
        this.state = {
            loading: true
        };
    }

    render() {


        return (
            <Fragment>
                <Card>
                    <CardHeader style={{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>

                                    <span>

                                    Help
                                    </span>

                        <div>


                        </div>
                    </CardHeader>


                    <CardBody>

                        <h5>Tutorials</h5>
                        <p>Select a tutorial below to be taken through a step by step tour.</p>

                        {Object.keys(tours).map((key)=>(

                            <div>

                                <h6>{key}</h6>

                                {tours[key].map((item)=>(

                                    <p>
                                        <a href={'#'} onClick={()=>{this.props.openTour(item.steps, item.nav)}}>{item.label}</a>
                                    </p>

                                ))}

                            </div>

                        ))}

                    </CardBody>
                </Card>
            </Fragment>
        )
    }
}
