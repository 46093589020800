import React, {Component, Fragment} from 'react';
import {Container, Row, Col, Card, CardHeader, CardBody,Button, Form,
    FormGroup, Input, FormText,} from "reactstrap";

export default class UploadLeavers extends Component {

    render() {
        return (
            <Fragment>
                <Container>
                    <Row className={'mb-3'}>
                        <Col>
                            <Card>
                                <CardHeader>
                                    File Format
                                </CardHeader>


                                <CardBody>

                                    <p>File types accepted: CSV</p>
                                    <p>Your uploaded file must include the following columns (in any order):</p>
                                    <ul>
                                        <li>First Name</li>
                                        <li>Last Name</li>
                                        <li>Class</li>
                                    </ul>
                                    <p>The first row must be the column names.</p>
                                    <p>If your naming convention uses any other student details such as student ID numbers, please ensure this data is also included in the file.</p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    Upload
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <FormGroup>
                                            <Input type="file" name="file" id="exampleFile"/>
                                            <FormText color="muted">
                                                Important: Please ensure this is your student list, not your class list. The uploaded file must be of CSV format.
                                            </FormText>
                                            <Button color="success" className="mt-1">Upload</Button>

                                        </FormGroup>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
