import React from 'react'
import Initial from './Blocks/Initial'


export default class Preview extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            header: null,
            footer: null,
            id: props.getID()
        }
    }

    componentWillMount() {

        fetch('/api/newsletter/send/builder/template', {credentials:'same-origin'})
            .then(res => res.json())
            .then(resJSON => {

                if (resJSON.success){
                    this.setState(resJSON);
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                }

            })
    }

    render() {

        let isInitial = true;
        if (this.props.elements.length > 0){
            isInitial = false;
        }

        // noinspection JSUnresolvedFunction
        return (
            <div style={this.props.isColumn?{}:{maxWidth:600}}>

                {this.props.isColumn?null:<div dangerouslySetInnerHTML={{__html: this.state.header}}/>}

                {isInitial?<Initial addElement={this.props.addElement} column={this.props.column} addMovedElement={this.props.addMovedElement} isColumn={this.props.isColumn}/>:null}
                {this.props.elements.map((item, index) => {
                    return(
                    React.cloneElement(
                        item.element,
                        {
                            settings:item.settings,
                            updateElement: this.props.updateElement,
                            updateElementData: this.props.updateElementData,
                            elements: this.props.elements,
                            index:index,
                            setStyleSettings: this.props.setStyleSettings,
                            setTab: this.props.setTab,
                            removeElement: this.props.removeElement,
                            moveElement: this.props.moveElement,
                            addElement: this.props.addElement,
                            column: this.props.column,
                            setElementsForToolbox: this.props.setElementsForToolbox,
                            isColumn: this.props.isColumn,
                            addMovedElement: this.props.addMovedElement,
                            parent: this.state.id,
                            getID: this.props.getID,
                            type: item.type,
                        }
                    )


                    )}
                    )}

                {this.props.isColumn?null:<div dangerouslySetInnerHTML={{__html: this.state.footer}}/>}

            </div>
            )
    }
}
