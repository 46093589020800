import React from 'react'
import {Input} from "reactstrap";

export default class Attachment extends React.Component{

    constructor(props){
        super(props);

        this.state={};
    }

    render() {
        return(
            <div onClick={(e)=>{

                if (e.target.name === 'delete') return;

                this.props.setStyleSettings([
                    {
                        type: 'color',
                        label: 'Background Colour',
                        id: 'backgroundColor',
                        value: '#000000'
                    },{
                        type: 'number',
                        label: 'Padding',
                        id: 'padding',
                        value: 0
                    },{
                        type: 'number',
                        label: 'Margin',
                        id: 'margin',
                        value: 0
                    },{
                        type: 'border',
                        label: 'Border',
                        id: 'border',
                        value: 0
                    },
                    {
                        type: 'fileSource',
                        label: 'File Source',
                        id: 'fileSource',
                    }

                ], this.props.index, this.props.column);

                this.props.setTab('2');
            }}
            >

                <div style={{display:'flex', justifyContent:'center', alignItems: 'center'}}>


                    {this.props.elements[this.props.index].data.text === "Upload a file"?

                        <div style={{display:'table', padding:10, width: '100%'}}><div style={{display:'table-cell'}}>Upload Attachment:</div>

                            <Input style={{display:'table-cell'}} type="file" onChange={(e)=>{

                                let formData = new FormData();
                                formData.append("section-image-file",e.target.files[0]);
                                fetch('/api/newsletter/send/builder/upload', {
                                    method: 'POST',
                                    // headers: {'Content-Type':'multipart/form-data'},
                                    body: formData}
                                )
                                    .then((res) => res.json())
                                    .then((resJSON)=>{
                                        this.props.elements[this.props.index].data.url = resJSON['url'];
                                        this.props.elements[this.props.index].data.text = resJSON['text'];
                                        this.props.updateElementData();
                                    })

                            }}/>

                        </div>
                        :
                        <a href={this.props.elements[this.props.index].data.url} target="_blank">Download: {this.props.elements[this.props.index].data.text}</a>



                    }



                </div>


            </div>
        )
    }

}

