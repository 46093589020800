const steps = [
    // {
    //     selector: '#menu-support',
    //     content: "Choose 'Support' tab"
    // },
    {
        selector: '#menu-support-view',
        content: "Choose 'My Tickets' to view all your tickets"
    },
    {
        selector: '#menu-support-ticket',
        content: "Select 'New Support ticket'"
    },
    {
        selector: '#root',
        content: 'Choose a department',
    },
    {
        selector: 'iframe',
        content: "Enter your details and click 'Submit'. A KiwiSchools representative will contact you shortly"
    }
];

export default steps;
