import {useDrop} from "react-dnd";
import React from "react";

export default function Initial(props){

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: 'element',
        drop: () => ({ name: 'initial', index: 0, addElement: props.addElement, column: props.column, addMovedElement: props.addMovedElement , isColumn: props.isColumn}),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const isActive = canDrop && isOver;
    let backgroundColor = '#fff';
    if (isActive) {
        backgroundColor = 'lightblue'
    } else if (canDrop) {
        backgroundColor = 'lightgray'
    }

    return (

        <div
            style={{

                border: 'solid',
                borderWidth: 3,
                borderStyle: 'dashed',
                borderRadius: 10,

                width: '100%',
                height: 100,

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: backgroundColor

            }}

             ref={drop}
        >
            Drag and drop a block to get started
        </div>

    )
}

