const steps = [
    {
        selector: '#menu-website',
        content: "Choose 'Website' tab'",
        nav: false,
    },
    {
        selector: '#menu-website-posts',
        content: "Choose 'Manage Posts'",
        nav: false,
    },
    {
        selector: '#editButtonnew',
        content: "Select 'Add New'",
        nav: false,
    },
    {
        selector: '.modal-content',
        content: 'Add a title, select a category, and add your post content. When you are ready to publish, click the create button.',
        nav: false,

    }
];

export default steps;
