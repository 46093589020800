import React, {Component, Fragment} from 'react';
import {
    Card, CardHeader, CardBody, Badge, } from "reactstrap";

import Table from "reactstrap/es/Table";
import TicketView from "./Components/TicketView"
import Loader from "react-loaders";


export default class NewTicket extends Component {

    constructor(props){
        super(props);
        this.state = {
            tickets: [],
            ticketModalOpen: false,
            loading: true
        };

        this.onOpenTicket = this.onOpenTicket.bind(this);
        this.onCloseTicket = this.onCloseTicket.bind(this);

    }

    componentWillMount() {

        fetch('/api/support/tickets', {credentials: 'same-origin'})
            .then(res=>res.json())
            .then(resJSON=>{
                if (resJSON.success) {
                    this.setState({
                        tickets: resJSON.tickets,
                        loading: false
                    });
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                }
            });
    }

    onOpenTicket(id, ticketData) {

        this.setState({
            ticketData: ticketData,
            ticketModalOpen: true
        });
    }

    onCloseTicket(){
        this.setState({ticketModalOpen: false})
    }

    render() {
        return (
            <Fragment>
                <Card>
                    <CardHeader style={{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>

                                    <span>
                                        My Tickets
                                    </span>

                        <div>


                        </div>
                    </CardHeader>


                    <CardBody style={{padding: 0, width: '100%', overflow: 'auto'}}>

                        <Table hover>
                            <thead>
                            <tr>
                                <th style={{paddingLeft: '1.25rem'}}>#</th>
                                <th>Subject</th>
                                <th>Department</th>
                                <th>Priority</th>
                                <th>Status</th>
                                <th>Last Updated</th>
                            </tr>
                            </thead>
                            <tbody>

                            {this.state.tickets.map((ticket)=>(
                                <tr className={'hover-row'} onClick={()=> {this.onOpenTicket(ticket.id, {
                                    subject: ticket.subject,
                                    number: ticket.number
                                })}}>
                                    <th scope="row" style={{paddingLeft: '1.25rem'}}>{ticket.number}</th>
                                    <td>{ticket.subject}</td>
                                    <td>{ticket.department}</td>
                                    <td><Badge style={{backgroundColor: ticket.priority.colour}} pill>{ticket.priority.name}</Badge></td>
                                    <td><Badge style={{backgroundColor: ticket.status.colour}} pill>{ticket.status.name}</Badge></td>
                                    <td style={{paddingRight: '1.25rem'}}>{new Date(ticket.date_updated*1000).toLocaleString()}</td>
                                </tr>
                                )
                            )}

                            </tbody>
                        </Table>

                        {this.state.loading?<div style={{display: 'flex', justifyContent: 'center'}}>
                            <Loader active type={'ball-pulse'}/>
                        </div>:null}

                    </CardBody>
                </Card>

                {this.state.ticketModalOpen?
                <TicketView data={this.state.data} ticketData={this.state.ticketData} toggle={this.onCloseTicket} />:null}

            </Fragment>
        )
    }
}
