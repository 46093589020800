import 'react-dropzone-uploader/dist/styles.css'
import React, {Fragment} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardHeader, CardBody} from 'reactstrap';
import Input from "reactstrap/es/Input";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import {toast} from "react-toastify";
import BlockUi from "react-block-ui";
import {Loader} from "react-loaders";



export default class NewGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            title: '',
            loading: false
        };

        this.toggle = this.toggle.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onTitleChange = this.onTitleChange.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });


    }

    onSave(){
        this.setState({loading: true});

        fetch('/api/website/gallery/new', {
            credentials: 'same-origin',
            method: 'post',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({title: this.state.title})
        })
            .then(res => res.json())
            .then(resJSON=>{

                this.setState({loading: false, title: ''});
                if (resJSON.success){

                    this.props.history.push('/website/galleries');

                    toast['success'](<span><strong>Success!</strong> New gallery created!</span>);

                }else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    toast['error'](<span><strong>Failed!</strong> Please try again!</span>);

                }
                this.toggle();


            });

    }

    onTitleChange(e){
        this.setState({title: e.target.value});
    }


    render() {

        return (
            <Fragment>


                <Card>

                    <CardHeader style={{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                                    <span>
                                        New Gallery
                                    </span>
                    </CardHeader>

                    <CardBody>
                        <BlockUi tag="div" blocking={this.state.loading}  style={{overflow:'hidden'}} loader={<Loader color="#ffffff" active type={'ball-clip-rotate'}/>}>
                        <div>

                             <FormGroup>
                                <Label for={'title'}>Title</Label>
                                <Input name={'title'} value={this.state.title} onChange={this.onTitleChange}/>
                            </FormGroup>

                        </div>
                        </BlockUi>

                        <div style={{display:"flex", justifyContent:'flex-end'}}>

                        <Button color="link" onClick={()=>this.props.history.push(`/website/galleries`)}>Cancel</Button>
                        <BlockUi tag="div" blocking={this.state.loading}  style={{overflow:'hidden'}} loader={<Loader color="#ffffff" active type={'ball-clip-rotate'}/>}>
                        <Button color="primary" onClick={this.onSave}>Create</Button>
                        </BlockUi>

                        </div>

                    </CardBody>
                </Card>

            </Fragment>
        );
    }
}
