import React, {Component, Fragment} from 'react';
import {Container, Row, Col, Card, CardHeader, CardBody,} from "reactstrap";


export default class Products extends Component {

    constructor(props){
        super(props);
        this.state = {
        };
    }

    componentWillMount() {


    }

    render() {
        return (
            <Fragment>

                <Container>
                    <Row className={'mb-3'}>
                        <Col>
                            <Card>
                                <CardHeader style={{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>
                                    <span>
                                    My Products
                                    </span>
                                </CardHeader>

                                <CardBody style={{padding:0}}>

                                    <div className="content table-responsive table-full-width">
                                        <table className="table table-hover table-striped">
                                            <thead>
                                                <th>Product</th>
                                                <th>Enabled</th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Learning in the Cloud</td>
                                                    <td>
                                                        {this.props.account.hapara?
                                                            <i className="lnr lnr-checkmark-circle" style={{color: 'green'}}/>
                                                            :
                                                            <i className="lnr lnr-cross-circle" style={{color: 'darkred'}}/>

                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Newsletter</td>
                                                    <td>
                                                        {this.props.account.newsletter?
                                                            <i className="lnr lnr-checkmark-circle" style={{color: 'green'}}/>
                                                            :
                                                            <i className="lnr lnr-cross-circle" style={{color: 'darkred'}}/>

                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Newsletter Builder</td>
                                                    <td>
                                                        {this.props.account.builder?
                                                            <i className="lnr lnr-checkmark-circle" style={{color: 'green'}}/>
                                                            :
                                                            <i className="lnr lnr-cross-circle" style={{color: 'darkred'}}/>

                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Website (KiwiSchools 4.0)</td>
                                                    <td>
                                                        {this.props.account.website?
                                                            <i className="lnr lnr-checkmark-circle" style={{color: 'green'}}/>
                                                            :
                                                            <i className="lnr lnr-cross-circle" style={{color: 'darkred'}}/>

                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>App (KSConnect)</td>
                                                    <td>
                                                        {this.props.account.app?
                                                            <i className="lnr lnr-checkmark-circle" style={{color: 'green'}}/>
                                                            :
                                                            <i className="lnr lnr-cross-circle" style={{color: 'darkred'}}/>

                                                        }
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <h5 style={{paddingTop:5}} className="text-center">Please contact your KiwiSchools account manager to
                                        enquire about disabled products.</h5>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
