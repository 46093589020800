import React, {Fragment} from 'react';
import ManageStaffList from './managestafflist';
import StaffEditor from './Components/StaffEditorPage';
import Posts from '../Website/posts';
import {Route, Switch} from 'react-router-dom';

const Hapara = ({match}) => (
    <Fragment>
        <Switch>
        <Route path={match.url + "/managestaff/edit"} component={StaffEditor}/>
        <Route path={match.url + "/managestaff"} component={ManageStaffList}/>
        <Route path={match.url + "/send"} component={Posts}/>
        </Switch>
    </Fragment>
);

export default Hapara;
