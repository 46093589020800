import React from 'react'
import {Input} from "reactstrap";

export default class Image extends React.Component{

    constructor(props){
        super(props);

        this.state={};
    }

    render() {
        return(
            <div onClick={(e)=>{

                     if (e.target.name === 'delete') return;

                     this.props.setStyleSettings([
                         {
                             type: 'number',
                             label: 'Padding',
                             id: 'padding',
                             value: 0
                         },{
                             type: 'elementNumber',
                             label: 'Width',
                             id: 'width',
                             value: 0
                         },{
                             type: 'elementNumber',
                             label: 'Height',
                             id: 'height',
                             value: 0
                         },
                         {
                             type: 'imageSource',
                             label: 'Image Source',
                             id: 'imageSource',
                             value: 'https://via.placeholder.com/500x200.png?text=No+Image+Uploaded'
                         },
                         {
                             type: 'imagePosition',
                             label: 'Image Fit',
                             id: 'imageFit',
                             value: 'cover'
                         },
                         {
                             type: 'borderImage',
                             label: 'Border',
                             id: 'borderImage',
                         },
                         {
                             type: 'numberPX',
                             label: 'Border Radius',
                             id: 'borderRadius',
                             value: 0
                         }
                     ], this.props.index, this.props.column);

                     this.props.setTab('2');
                 }}
            >

                <div style={{display:'flex', justifyContent:'center', alignItems: 'center'}}>

                    {this.props.elements[this.props.index].data === "https://via.placeholder.com/500x200.png?text=No+Image+Uploaded" ?

                        <div style={{display:'table', padding:10, width: '100%'}}><div style={{display:'table-cell'}}>Upload Image:</div><Input style={{display:'table-cell'}} type="file" onChange={(e) => {

                            let formData = new FormData();
                            formData.append("section-image-file", e.target.files[0]);
                            fetch('/api/newsletter/send/builder/upload', {
                                    method: 'POST',
                                    // headers: {'Content-Type':'multipart/form-data'},
                                    body: formData
                                }
                            )
                                .then((res) => res.json())
                                .then((resJSON) => {
                                    this.props.elements[this.props.index].data = resJSON['url'];
                                    this.props.updateElementData();
                                })

                        }}/></div>:
                        <img src={this.props.elements[this.props.index].data} style={{
                            width: this.props.elements[this.props.index].settings['width'] ? parseInt(this.props.elements[this.props.index].settings['width']) : '100%',
                            height: this.props.elements[this.props.index].settings['height'] ? parseInt(this.props.elements[this.props.index].settings['height']) : 'auto',
                            objectFit: this.props.elements[this.props.index].settings['imageFit'] ? this.props.elements[this.props.index].settings['imageFit'] : 'cover',
                            borderRadius: this.props.elements[this.props.index].settings['borderRadius'] ? this.props.elements[this.props.index].settings['borderRadius'] : '0',
                        }}/>

                    }

                </div>


            </div>
        )
    }

}

