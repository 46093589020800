import account_users from './Account/users';
import website_posts from './Website/posts';
import support_ticket from './Support/ticket';
import app_send from './App/send';

const tutorials = {

    'Account': [
        {
            label: 'How do I authorise additional users to use KiwiSchools Central?',
            steps: account_users,
            nav: false
        }
    ],

    'Website': [
        {
            label: "How do I send a new Newsflash or Newsletter on my Website?",
            steps: website_posts,
            nav: false
        }
    ],
    'Support': [
        {
            label: 'How can I create a support ticket?',
            steps: support_ticket,
            nav: false
        }
    ],
    'App': [
        {
            label: 'How do I send a push notification to my school\'s app',
            steps: app_send,
            nav: false
        }
    ]

};

export default tutorials;
