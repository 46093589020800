import Container from "./Blocks/Container";
import Text from "./Blocks/Text";
import Image from "./Blocks/Image";
import Divider from "./Blocks/Divider";
import Spacer from "./Blocks/Spacer";
import Attachment from "./Blocks/Attachment";
import Columns from "./Blocks/Columns";
import React from "react";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import Color from "./Color";

export const blocks = {
    text: {
        element: <Container element={<Text/>}/>,
        data: '',
        block: {
            text: 'Text',
            icon: 'lnr-text-format',
            type: 'text'
        }},
    image: {
        element: <Container element={<Image/>}/>,
        data: 'https://via.placeholder.com/500x200.png?text=No+Image+Uploaded',
        block: {
            text: 'Image',
            icon: 'lnr-picture',
            type: 'image'
        }},
    divider: {
        element: <Container element={<Divider/>}/>,
        block: {
            text: 'Divider',
            icon: 'lnr-page-break',
            type: 'divider'
        }},
    spacer: {
        element: <Container element={<Spacer/>}/>,
        block: {
            text: 'Spacer',
            icon: 'lnr-page-break',
            type: 'spacer'
        }},
    attachment: {
        element: <Container element={<Attachment/>}/>,
        data: {url: 'https://via.placeholder.com/500x200.png?text=File+Not+Found', text: 'Upload a file'},
        block: {
            text: 'Attachment',
            icon: 'lnr-paperclip',
            type: 'attachment'
        }},
    columns: {
        element: <Container element={<Columns/>}/>,
        data: {elements: [[],[],[]], settings:[]},
        block: {
            text: 'Columns',
            icon: 'lnr-license',
            type: 'columns'
        }},
};

function handleChangeNumber(props, id, value){
    let settings = {};
    settings[id] = value.target.value?value.target.value:0;

    if (id.indexOf('border') > -1){
        let color = props.elements[props.column][props.index].settings['borderColor']?props.elements[props.column][props.index].settings['borderColor']:'black';
        settings[id] = settings[id] + 'px solid ' + color;
    } else {
        settings[id] = parseInt(settings[id]);
    }


    props.setElementSettings(settings, props.column, props.index);

}


export function getInputType(props, value, setting){

    const inputTypes = {
        'number': <Input type="text" id={setting.id} value={value} onChange={(value)=>{
            let settings = {};
            settings[setting.id] = parseInt(value.target.value?value.target.value:0);
            props.setElementSettings(settings, props.column, props.index);
        }}/>,
        'numberPX': <Input type="text" id={setting.id} value={value} onChange={(value)=>{
            let settings = {};
            settings[setting.id] = parseInt(value.target.value?value.target.value:0);
            props.setElementSettings(settings, props.column, props.index);
        }}/>,
        'borderImage':
            <Row>
                <Col>
                    <Input type="text" id={setting.id} value={value} onChange={(value)=>{
                        let settings = {};
                        settings[setting.id] = parseInt(value.target.value?value.target.value:0);
                        settings['borderColor'] = props.elements[props.column][props.index].settings['borderColor'];

                        let output = {border: `${settings[setting.id]}px solid ${settings['borderColor']?settings['borderColor']:'black'}`};

                        output[setting.id] = settings[setting.id];
                        props.setElementSettings(output, props.column, props.index);
                    }}/>
                </Col>
                <Col style={{display:'flex',alignItems: 'center'}}>
                    <div style={{marginBottom:5}}>

                        <Color
                            color={props.elements[props.column][props.index].settings['borderColor'] ? props.elements[props.column][props.index].settings['borderColor'] : '#000000'}
                            property={'borderColor'}
                            setElementSettings={props.setElementSettings}
                            column={props.column}
                            index={props.index}
                        />
                    </div>
                </Col>
            </Row>
        ,
        'elementNumber': <Input type="text" id={setting.id} value={props.elements[props.column][props.index].settings[setting.id]} onChange={(value)=>{
            props.elements[props.column][props.index].settings[setting.id] = value.target.value;
            props.updateElementData();

        }}/>,
        'color': <Color
            color={props.elements[props.column][props.index].settings[setting.id]?props.elements[props.column][props.index].settings[setting.id]:'#ffffff'}
            onChangeComplete={ (color)=>{
                props.setElementSettings({
                    backgroundColor: color.hex
                }, props.column, props.index)
            }}
            width={'80%'}
            property={'backgroundColor'}
            setElementSettings={props.setElementSettings}
            column={props.column}
            index={props.index}
        />,
        'imageSource': <Input type="file" id={setting.id} onChange={(e)=>{

            let formData = new FormData();
            formData.append("section-image-file",e.target.files[0]);
            fetch('/api/newsletter/send/builder/upload', {
                method: 'POST',
                // headers: {'Content-Type':'multipart/form-data'},
                body: formData}
            )
                .then((res) => res.json())
                .then((resJSON)=>{


                    props.elements[props.column][props.index].data = resJSON['url'];
                    props.updateElementData();
                })

        }}/>,
        'fileSource': <Input type="file" id={setting.id} onChange={(e)=>{

            let formData = new FormData();
            formData.append("section-image-file",e.target.files[0]);
            fetch('/api/newsletter/send/builder/upload', {
                method: 'POST',
                // headers: {'Content-Type':'multipart/form-data'},
                body: formData}
            )
                .then((res) => res.json())
                .then((resJSON)=>{
                    props.elements[props.column][props.index].data.url = resJSON['url'];
                    props.elements[props.column][props.index].data.text = resJSON['text'];
                    props.updateElementData();
                })

        }}/>,
        'imagePosition':
            <Input type="select" id={setting.id} value={props.elements[props.column][props.index].settings[setting.id]?props.elements[props.column][props.index].settings[setting.id]:setting.value} onChange={(e)=>{
                props.elements[props.column][props.index].settings[setting.id] = e.target.value;
                props.updateElementData();
            }}>
                <option value={'cover'}>Fit to container</option>
                <option value={'contain'}>Fit inside container</option>
            </Input>,
        'columnSize':
            <div>

                <Input type="select" id={setting.id} value={props.elements[props.column][props.index].settings[setting.id]?props.elements[props.column][props.index].settings[setting.id]:setting.value} onChange={(e)=>{
                    props.elements[props.column][props.index].settings[setting.id] = e.target.value;
                    props.updateElementData();
                }}>
                    <option value={'6,6'}>50%, 50%</option>
                    <option value={'4,8'}>33%, 66%</option>
                    <option value={'8,4'}>66%, 33%</option>
                </Input>
            </div>,
        'showDivider':
            <div>

                <Input type="select" id={setting.id} value={props.elements[props.column][props.index].settings[setting.id]?props.elements[props.column][props.index].settings[setting.id]:setting.value} onChange={(e)=>{
                    props.elements[props.column][props.index].settings[setting.id] = e.target.value;
                    props.updateElementData();
                }}>
                    <option value={'1'}>Yes</option>
                    <option value={'0'}>No</option>
                </Input>
            </div>



    };


    if (['padding','margin','border'].indexOf(setting.id) > -1){

        let valueMap = [
            parseInt(props.elements[props.column][props.index].settings[setting.id + 'Top'])?props.elements[props.column][props.index].settings[setting.id + 'Top']:'',
            parseInt(props.elements[props.column][props.index].settings[setting.id + 'Right'])?props.elements[props.column][props.index].settings[setting.id + 'Right']:'',
            parseInt(props.elements[props.column][props.index].settings[setting.id + 'Bottom'])?props.elements[props.column][props.index].settings[setting.id + 'Bottom']:'',
            parseInt(props.elements[props.column][props.index].settings[setting.id + 'Left'])?props.elements[props.column][props.index].settings[setting.id + 'Left']:'',
        ];

        return (
            <FormGroup>
                <Label for={setting.id + 'Left'}>{setting.label}</Label>

                {setting.id === 'border'?
                    <div style={{marginBottom:5}}>

                        <Color
                            color={props.elements[props.column][props.index].settings['borderColor'] ? props.elements[props.column][props.index].settings['borderColor'] : '#000000'}
                            property={'borderColor'}
                            setElementSettings={props.setElementSettings}
                            column={props.column}
                            index={props.index}
                        />
                    </div>
                    :null

                }

                <Row style={{marginRight:5}}>
                    <Col xs={3}>
                        Top<Input type="text" id={setting.id} value={String(valueMap[0]).split('px')[0]} onChange={(value)=>{handleChangeNumber(props, setting.id + 'Top', value)}}/>

                    </Col>
                    <Col xs={3}>
                        Right<Input type="text" id={setting.id} value={String(valueMap[1]).split('px')[0]} onChange={(value)=>{handleChangeNumber(props,setting.id + 'Right', value)}}/>

                    </Col>
                    <Col xs={3}>
                        Bottom<Input type="text" id={setting.id} value={String(valueMap[2]).split('px')[0]} onChange={(value)=>{handleChangeNumber(props,setting.id + 'Bottom', value)}}/>

                    </Col>
                    <Col xs={3}>
                        Left<Input type="text" id={setting.id} value={String(valueMap[3]).split('px')[0]} onChange={(value)=>{handleChangeNumber(props,setting.id + 'Left', value)}}/>

                    </Col>
                </Row>

            </FormGroup>
        )
    }

    return (
        <FormGroup>
            <Label for={setting.id}>{setting.label}</Label>
            {React.cloneElement(
                inputTypes[setting.type],
                {
                    name: setting.id
                }
            )}
        </FormGroup>
    )

};
