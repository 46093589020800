import React, {Fragment} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, CardHeader, CardBody, Card} from 'reactstrap';
import Input from "reactstrap/es/Input";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";

import BlockUi from 'react-block-ui';
import {Loader} from "react-loaders";
import {toast} from "react-toastify";


export default class NewMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            data:{},
            loading: false,
            categories:[],
            category:{}
        };


        this.toggle = this.toggle.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSend = this.onSend.bind(this);
    }



    toggle() {
        this.setState({
            modal: !this.state.modal,
            data:{
                from: '',
                subject: '',
                file: '',
                attachment: undefined
        }
        });
    }

    onInputChange(event) {

        this.state.data[event.target.name] = event.target.value;

        if (event.target.files) {
            this.state.data['attachment'] = event.target.files[0]
        }

        this.setState({data: this.state.data});
    }

    notify(type, text) {
        toast[type](text);

    }
    onSend(){

        if (!this.state.data['from'] || !this.state.data['subject'] || this.state.data['from'] === '' || this.state.data['subject'] === ''){
            return this.notify('error', <span><strong>Failed!</strong> Please enter a from name and subject.</span>)

        }

        let formData = new FormData();

        formData.append('from', this.state.data['from']);
        formData.append('subject', this.state.data['subject']);
        formData.append('build', JSON.stringify( this.props.location.state.build));
        formData.append('attachment', this.state.data['attachment']);
        formData.append('category', JSON.stringify(this.state.category));

        if (this.props.location.state.test){
            formData.append('test', 'true');

        }


        this.setState({loading: true});

        fetch('/api/newsletter/send/builder', {
            credentials: 'same-origin',
            method: 'post',
            // headers: {
            //     'Content-Type': "application/json"
            // },
            body: formData
        })
            .then(res => res.json())
            .then(resJSON=>{

                this.setState({loading: false});
                if (resJSON.success){

                    this.setState({
                        category: {},
                        data:{
                            from: '',
                            subject: '',
                            build: '',
                            file: '',
                            attachment: undefined
                        }});

                    this.notify('success', <span><strong>Success!</strong> Your newsletter has been sent!</span>);
                    this.props.history.push('/newsletter/send/builder');
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.notify('error', <span><strong>Failed!</strong> Please try again!</span>)

                }

            })
    }

    componentDidMount() {

        fetch('/api/website/wordpress/categories', {
            credentials: 'same-origin',
        })
            .then(res => res.json())
            .then(resJSON=>{

                this.setState({loading: false});
                if (resJSON.success){
                    this.setState({categories: resJSON.categories});

                    resJSON.categories.forEach((item)=>{
                        if (item.name === "Newsletters"){
                            this.setState({
                                category: {...this.state.category, [String(item.id)]: item.name}
                            });
                        }
                    })

                }else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.notify('error', <span><strong>Failed!</strong> Couldn't load categories</span>)
                }

            });

    }

    render() {


        return (
            <Fragment>

                <Card>

                    <CardHeader style={{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>

                                    <span>
                                        {this.props.location.state.test?"Send Test Email":"Send Email"}
                                    </span>
                    </CardHeader>

                    <CardBody>

                        <FormGroup>
                            <Label for="from">From</Label>
                            <Input type="text" name="from" id="from"
                                   placeholder="Sender Name eg. School Name Office" value={this.state.data['from']} onChange={this.onInputChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="subject">Subject</Label>
                            <Input type="text" name="subject" id="subject" value={this.state.data['subject']} onChange={this.onInputChange}/>
                        </FormGroup>

                        {this.props.location.state.test?null:
                            <FormGroup>
                                <Label for={'category'}>Website Category</Label>
                                {this.state.categories.map((item) => (
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" onChange={()=>{
                                                if (Object.keys(this.state.category).indexOf(String(item.id)) > -1){

                                                    let old_category = this.state.category;
                                                    delete old_category[String(item.id)];
                                                    this.setState({
                                                        category: old_category
                                                    });
                                                } else {
                                                    this.setState({
                                                        category: {...this.state.category, [String(item.id)]: item.name}
                                                    });
                                                }

                                            }}
                                                   checked={Object.keys(this.state.category).indexOf(String(item.id)) > -1}/> {item.name}
                                        </Label>
                                    </FormGroup>
                                ))}
                            </FormGroup>
                        }

                        <FormGroup>
                            <Label for="exampleFile">File Upload</Label>
                            <Input type="file" name="file" id="file" value={this.state.data['file']} onChange={this.onInputChange}/>
                        </FormGroup>

                        <div style={{display:"flex", justifyContent:'flex-end'}}>
                        <Button color="link" onClick={()=>this.props.history.push('/newsletter/send/builder')}>Cancel</Button>
                        <BlockUi tag="div" blocking={this.state.loading}  style={{overflow:'hidden'}}
                                 loader={<Loader color="#ffffff" active type={'ball-clip-rotate'}/>}>
                        <Button color="primary" onClick={this.onSend}>Send</Button>
                        </BlockUi>
                        </div>
                    </CardBody>
                </Card>
            </Fragment>
        );
    }
}
