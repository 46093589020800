import React from 'react';
import { withRouter } from 'react-router-dom';

class RouterLink extends React.Component {
    constructor() {
        super();

        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {

        if (this.props.hasSubMenu) this.props.toggleSubMenu(e);
        else {

            this.props.history.push(this.props.to);
            this.props.activateMe({
                newLocation: this.props.to,
                selectedMenuLabel: this.props.label,
            });
        }
    }

    render() {
        let active = "";
        if (this.props.active){
            active = this.props.classNameActive;
        }
        if (this.props.hasActiveChild){
            active = this.props.classNameHasActiveChild;

        }

        return (
            <a href={'#'} className={"metismenu-link " + active} onClick={this.onClick} id={this.props.id}>
                {this.props.children}
            </a>
        )
    }
}

export default withRouter(RouterLink);
