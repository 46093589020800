const steps = [
    {
        selector: '#menu-app',
        content: "Choose 'App' tab'",
        nav: false,
    },
    {
        selector: '#menu-app-send',
        content: "Choose 'Send Notification'",
        nav: false,
    },
    {
        selector: '#editButtonnew',
        content: "Select 'Add New'",
        nav: false,
    },
    {
        selector: '.modal-content',
        content: "Add a title, select 'Newsflash' as the category, and add your push notification content. When you are ready to publish, click the create button.",
        nav: false,

    }
];

export default steps;
