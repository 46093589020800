import React, {Component, Fragment} from 'react';
import {Container, Row, Col, Card, CardHeader, CardBody,Button, } from "reactstrap";

import ReactTable from "react-table";
import StudentEditor from "./Components/StudentEditor";

import { toast } from 'react-toastify';
import NewStudent from "./Components/NewStudent";


export default class StudentList extends Component {

    constructor(props){
        super(props);
        this.state = {
            list: [],
            loading: true,
            classes: [],
            fields: []
        };

        this.updateStudentList = this.updateStudentList.bind(this);
        this.updateStudentListNew = this.updateStudentListNew.bind(this)
    }

    componentWillMount() {

        fetch('/api/hapara/manage/studentlist', {credentials: 'same-origin'})
            .then(res=>res.json())
            .then(resJSON=>{

                if (resJSON.success) {
                    this.setState({list: resJSON.studentList, loading:false})
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.props.history.push('/hapara/upload');
                }
            });

            fetch('/api/hapara/manage/students', {credentials: 'same-origin'})
                .then(res=>res.json())
                .then(resJSON=> {

                    if (resJSON.success) {

                        this.setState({classes: resJSON.classes, fields: resJSON.fields})
                    } else if (resJSON['isAuthFailed']){
                        if (process.env.NODE_ENV === 'development'){
                            window.location.href = 'http://localhost:3001/api/login';
                        } else {
                            window.location.href = 'https://central.kiwischools.co.nz/api/login';
                        }
                    } else {
                        this.props.history.push('/hapara/upload');
                    }
                });


    }

    notify(type, text) {
        toast[type](text);

    }

    updateStudentList(index, data) {

        this.state.list[index] = {
            ...this.state.list[index],
            'First Name': data.firstname,
            'Last Name': data.lastname,
            'Class': data.class,
            'Email': data.email
        };

        this.setState({list: this.state.list})


    }
    updateStudentListNew(data) {

        this.state.list.push({
            'First Name': data.firstname,
            'Last Name': data.lastname,
            'Class': data.class,
            'Email': data.email,
            'Manage': ''
        });

        this.setState({list: this.state.list})


    }

    deleteEmail(index) {


        fetch('/api/hapara/manage/students/delete', {
            credentials: 'same-origin',
            method: 'post',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({index: index})
        })
            .then(res => res.json())
            .then(resJSON=>{

                if (resJSON.success){

                    this.state.list.splice(index, 1);
                    this.setState({list: this.state.list});


                    this.notify('success', <span><strong>Success!</strong> Your student deletion request is pending verification from KiwiSchools. We will notify you by email once the student is ready to go.</span>)
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.notify('danger', <span><strong>Failed!</strong> Please try again!</span>)

                }

            })



    }
    downloadList(){
        fetch('/api/hapara/manage/students/download', {
            credentials: 'same-origin',
        })
    .then((response) => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                window.open(url, '_blank');
                URL.revokeObjectURL(url);
            });
    }

    render() {
        return (
            <Fragment>

                <Container>
                    <Row className={'mb-3'}>
                        <Col>
                            <Card>
                                <CardHeader style={{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>

                                    <span>

                                    Student List
                                    </span>

                                    <div>
                                        <Button color={'secondary'} onClick={this.downloadList}>Export CSV</Button> &nbsp;
                                        <NewStudent
                                            classes={this.state.classes}
                                            fields={this.state.fields}
                                            notify={this.notify}
                                            updateStudentList={this.updateStudentListNew}
                                        />
                                    </div>
                                </CardHeader>


                                <CardBody>

                                    <ReactTable
                                        data={this.state.list}
                                        columns={[
                                            {
                                                Header: "First Name",
                                                accessor: "First Name"
                                            },
                                            {
                                                Header: "Last Name",
                                                accessor: 'Last Name'
                                            },
                                            {
                                                Header: "Class",
                                                accessor: 'Class'
                                            },
                                            {
                                                Header: "Email",
                                                accessor: 'Email'
                                            },
                                            {
                                                Header: "Manage",
                                                accessor: 'manage',
                                                filterable:false,
                                                Cell: props => (
                                                    <div>
                                                        <StudentEditor
                                                            data={props.original}
                                                            classes={this.state.classes}
                                                            notify={this.notify}
                                                            index={props.index}
                                                            updateStudentList={this.updateStudentList}
                                                            label={'Edit Details'}
                                                        />&nbsp;

                                                        <Button color={'danger'} className='btn-sm' onClick={()=> {this.deleteEmail(props.index)}}>Delete</Button>

                                                    </div>
                                                )
                                            }
                                        ]}
                                        defaultPageSize={10}
                                        filterable={true}
                                        loading={this.state.loading}
                                        loadingText={'Loading... This may take up to 30 seconds!'}
                                        className="-striped -highlight"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
