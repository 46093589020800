import React, {Component, Fragment} from 'react';
import {Container, Row, Col, Card, CardHeader, CardBody,} from "reactstrap";

import ReactTable from "react-table";
import {toast} from "react-toastify";

import Delete from './Components/Delete'
import NewMember from "./Components/NewMember";



export default class List extends Component {

    constructor(props){
        super(props);
        this.state = {
            members: [],
            loading: true
        };

        this.delete = this.delete.bind(this);
        this.add = this.add.bind(this);
    }

    componentWillMount() {

        fetch('/api/newsletter/manage/list/load', {credentials: 'same-origin'})
            .then(res=>res.json())
            .then(resJSON=>{
                if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                    return;
                }

                this.setState({members: resJSON, loading:false});
            })


    }


    notify(type, text) {
        toast[type](text);

    }

    delete(index){
        this.state.members.splice(index, 1);
        this.setState({members: this.state.members});
    }
    add(member){
        this.state.members.push({...member, subscribed: 'Yes'});
        this.setState({members: this.state.members});
    }

    render() {
        return (
            <Fragment>

                <Container>
                    <Row className={'mb-3'}>
                        <Col>
                            <Card>
                                <CardHeader style={{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>

                                    <span>

                                    Mailing List
                                    </span>

                                    <div>
                                        {/*<Button color={'secondary'} onClick={this.downloadList}>Export CSV</Button> &nbsp;*/}
                                        <NewMember
                                            notify={this.notify}
                                            updateList={this.add}
                                        />
                                    </div>
                                </CardHeader>


                                <CardBody>

                                    <ReactTable
                                        data={this.state.members}
                                        columns={[
                                            {
                                                Header: "Email",
                                                accessor: "address"
                                            },
                                            {
                                                Header: "Name",
                                                accessor: 'name'
                                            },
                                            {
                                                Header: "Subscribed",
                                                accessor: 'subscribed'
                                            },
                                            {
                                                Header: "Manage",
                                                accessor: 'manage',
                                                filterable:false,
                                                Cell: props => <Delete
                                                    email={props.original.address}
                                                    index={props.index}
                                                    notify={this.notify}
                                                    updateList={this.delete}
                                                />
                                            }
                                        ]}
                                        defaultPageSize={10}
                                        filterable={true}
                                        loading={this.state.loading}
                                        loadingText={'Loading... This may take up to 30 seconds!'}
                                        className="-striped -highlight"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
