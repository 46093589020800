import React, {Component, Fragment} from 'react';
import {Container, Row, Col, Card, CardHeader, CardBody,Table,
    } from "reactstrap";


export default class Status extends Component {

    constructor(props){
        super(props);
        this.state = {
            status:{}
        };
    }

    componentWillMount() {

        fetch('/api/hapara/status', {credentials: 'same-origin'})
            .then(res=>res.json())
            .then(resJSON=>{

                if (resJSON.success){
                    this.setState({status: resJSON.status});
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                }
            });


    }

    render() {
        return (
            <Fragment>

                <Container>
                    <Row className={'mb-3'}>
                        <Col>
                            <Card>
                                <CardHeader style={{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>

                                    <span>

                                    Status
                                    </span>
                                </CardHeader>


                                <CardBody style={{padding:0}}>

                                    <Table striped hover>
                                        <thead>
                                        <tr>
                                        <th>Domain</th>
                                        <th>Status</th>
                                        <th>Last Updated</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        <tr>
                                            <td>{this.state.status.domain}</td>
                                            <td>{this.state.status.status}</td>
                                            <td>{new Date(this.state['status']['updated']).toLocaleString()}</td>
                                        </tr>

                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
