import React from 'react'

export default class Spacer extends React.Component{

    constructor(props){
        super(props);

        this.state={};
    }

    render() {
        return(
            <div onClick={(e)=>{

                if (e.target.name === 'delete') return;

                this.props.setStyleSettings([
                    {
                        type: 'number',
                        label: 'Padding',
                        id: 'padding',
                        value: 0
                    }

                ], this.props.index, this.props.column);

                this.props.setTab('2');
            }}
            >

                <div style={{display:'flex',paddingTop:10, paddingBottom:10}}>
                    <div style={{height:1, width:'100%', backgroundColor: 'gray', opacity: 0}}>

                    </div>
                </div>


            </div>
        )
    }

}

