import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col} from 'reactstrap';
import Input from "reactstrap/es/Input";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import BlockUi from "react-block-ui";
import {Loader} from "react-loaders";


export default class StudentEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            data: this.props.data,
            loading: false
        };
        this.toggle = this.toggle.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onInsertAttachment = this.onInsertAttachment.bind(this);
    }



    toggle() {
        this.setState({
            modal: !this.state.modal,
            data: this.props.data
        });
    }

    onInputChange(event) {

        let fieldMap = {
            'name': 'Name',
            'class': 'Class',
            'role': 'Role',
            'email': 'Email',
            'imageURL': 'Image URL',
            'section': 'Section',
            'biography': 'Biography',
        };

        this.state.data[fieldMap[event.target.name]] = event.target.value;

        this.setState({data: this.state.data});
    }

    onSave(){

        let updateDetails = {
            "name": this.state.data['Name'],
            "class": this.state.data['Class'],
            "role": this.state.data['Role'],
            "email": this.state.data['Email'],
            "imageURL": this.state.data['Image URL'],
            "biography": this.state.data['Biography'],
            "section": this.state.data['Section'],
            index: this.props.index
        };

        let url = this.props.new?'/api/app/manage/stafflist/new':'/api/app/manage/stafflist/update';

        fetch(url, {
            credentials: 'same-origin',
            method: 'post',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify(updateDetails)
        })
            .then(res => res.json())
            .then(resJSON=>{
                this.toggle();

                if (resJSON.success){

                    this.setState({data: {
                            'Name': '',
                            'Class': '',
                            'Role': '',
                            'Email': '',
                            'Image URL': '',
                            'Biography': '',
                            'Section': '',
                        }});
                    if (!this.props.new){
                        this.props.updateStaffList(this.props.index, updateDetails);
                    } else {
                        this.props.refresh();
                    }

                    this.props.notify('success', <span><strong>Success!</strong> Your staff list has been updated.</span>)
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.props.notify('error', <span><strong>Failed!</strong> Please try again!</span>)

                }

            })

    }

    onInsertAttachment(e){
        let formData = new FormData();
        formData.append('file', e.target.files[0]);

        this.setState({loading: true});

        fetch('/api/website/media/upload', {
            credentials: 'same-origin',
            method: 'post',
            body: formData
        })
            .then(res => res.json())
            .then(resJSON=>{

                this.setState({loading: false});
                if (resJSON.success){


                    let fieldMap = {
                        'name': 'Name',
                        'class': 'Class',
                        'role': 'Role',
                        'email': 'Email',
                        'imageURL': 'Image URL',
                        'section': 'Section',
                        'biography': 'Biography',
                    };

                    this.state.data[fieldMap['imageURL']] = resJSON.media.source_url;

                    console.log(resJSON.media.source_url);

                    this.setState({data: this.state.data});

                }else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.notify('error', <span><strong>Failed!</strong> Please try again!</span>)

                }

            })
    }

    render() {


        return (
            <span className="d-inline-block">

                <Button className='btn-sm' onClick={this.toggle}>{this.props.label}</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="xl">
                    <ModalHeader toggle={this.toggle}>{this.props.new?'Add':'Edit'} Staff Member</ModalHeader>
                    <BlockUi tag="div" blocking={this.state.loading}  style={{overflow:'hidden'}} loader={<Loader color="#ffffff" active type={'ball-clip-rotate'}/>}>

                    <ModalBody>

                        <Row>
                            <Col>
                                 <FormGroup>
                                    <Label for={'name'}>Full Name</Label>
                                    <Input name={'name'} value={this.state.data['Name']} onChange={this.onInputChange}/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for={'section'}>Section</Label>
                                    <Input name={'section'} value={this.state.data['Section']} onChange={this.onInputChange}/>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for={'class'}>Class</Label>
                                    <Input name={'class'} value={this.state.data['Class']} onChange={this.onInputChange}/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for={'role'}>Role</Label>
                                    <Input name={'role'} value={this.state.data['Role']} onChange={this.onInputChange}/>
                                </FormGroup>
                            </Col>

                        </Row>

                        <FormGroup>
                            <Label for={'email'}>Email</Label>
                            <Input name={'email'} value={this.state.data['Email']} onChange={this.onInputChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for={'image'}>Image URL</Label>

                            <Row>
                                <Col sm={2}>
                                    <Input type="file" name="file" id="file" value={this.state.attachment} onChange={this.onInsertAttachment} style={{display: 'none'}}/> &nbsp;
                                    <Button style={{float: 'left'}} onClick={()=>{document.getElementById('file').click()}}>Upload Image</Button>
                                </Col>

                                <Col sm={10}>
                                    <Input name={'image'} value={this.state.data['Image URL']} onChange={this.onInputChange}/>

                                </Col>
                            </Row>


                        </FormGroup>

                        {this.state.data['Biography']?
                            <FormGroup>
                                <Label for={'biography'}>Bio</Label>
                                <Input name={'biography'} type="textarea" value={this.state.data['Biography']} onChange={this.onInputChange}/>
                            </FormGroup>
                        :
                        null}


                    </ModalBody>
                    </BlockUi>
                    <ModalFooter>
                        <Button color="link" onClick={this.toggle}>Cancel</Button>
                        <Button color="primary" onClick={this.onSave}>Save</Button>{' '}
                    </ModalFooter>
                </Modal>
            </span>
        );
    }
}
