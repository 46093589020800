import React, {Component, Fragment} from 'react';
import {
    Card, CardHeader, CardBody, Button, Input,
} from "reactstrap";

import ReactTable from "react-table";
import Editor from './Components/Editor'
import {toast} from "react-toastify";
import BlockUi from "react-block-ui";
import {Loader} from "react-loaders";



export default class List extends Component {

    constructor(props){
        super(props);
        this.state = {
            postList: [],
            categories : {},
            loading: true,
            pages: 0,
            loading_toggle: [],
            loading_delete: [],
            data: {}
        };

        this.toggleVisibility = this.toggleVisibility.bind(this);
        this.delete = this.delete.bind(this);
        this.fetchData = this.fetchData.bind(this);

        this.onInputChange = this.onInputChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.enterPressed = this.enterPressed.bind(this);
    }

    componentWillMount() {


    }

    toggleVisibility(id, isPublic, index){
        this.state.loading_toggle[index] = true;
        this.setState({loading_toggle: this.state.loading_toggle});

        fetch('/api/website/posts/toggle',{
            credentials: 'same-origin',
            method: 'post',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({id: id, isPublic: isPublic})
        })
            .then(res => res.json())
            .then(resJSON =>{
                this.state.loading_toggle[index] = false;
                this.setState({loading_toggle: this.state.loading_toggle});

                if (resJSON.success){
                    this.state.postList[index].post_status = isPublic?"draft":"publish";
                    this.setState({postList: this.state.postList});
                    toast['success']("Success! Post Updated.");
                }else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    toast['error']("Error! Please try again.");

                }
            })
    }
    delete(id, index){
        this.state.loading_delete[index] = true;
        this.setState({loading_delete: this.state.loading_delete});

        fetch('/api/website/posts/delete',{
            credentials: 'same-origin',
            method: 'post',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({id: id})
        })
            .then(res => res.json())
            .then(resJSON =>{
                this.state.loading_delete[index] = false;
                this.setState({loading_delete: this.state.loading_delete});

                if (resJSON.success){
                    this.setState({loading: true});
                    this.fetchData();
                    toast['success']("Success! Post deleted.");
                }else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    toast['error']("Error! Please try again.");

                }
            })
    }

    onInputChange(event) {

        this.state.data[event.target.name] = event.target.value;
        this.setState({data: this.state.data});
    }

    enterPressed(event){
        const code = event.keyCode || event.which;
        if(code === 13) {
            this.onSearch();
        }
    }

    onSearch() {

        this.fetchData();

    }

    fetchData = ()=>{
        this.setState({loading: true});

        const url = `/api/website/wordpress/posts?page=${this.state.table_state.page}&limit=${this.state.table_state.pageSize}${this.state.data['pageSearch']?'&query=' + this.state.data['pageSearch']:''}`;

        fetch(url, {credentials: 'same-origin'})
            .then(res=>res.json())
            .then(resJSON=>{
                if (resJSON.success) {
                    this.setState({
                        postList: resJSON.posts,
                        categories: resJSON.categories,
                        pages: resJSON.total_pages,
                        loading: false
                    });
                }else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                }
            });


        // OLD

        // fetch('/api/website/posts/' + (parseInt(this.state.table_state.page)+1), {credentials: 'same-origin'})
        //     .then(res=>res.json())
        //     .then(resJSON=>{
        //         this.setState({
        //             postList: resJSON.posts,
        //             categories: resJSON.categories,
        //             pages: resJSON.headers['x-wp-totalpages'],
        //             loading: false
        //         });
        //     })
    };

    isBlacklist(data){

        let isBlacklist = false;
        [
            'elementor',
            'table,td,th{border: none}',
            'gravityform'
        ].map((keyword) => {
            if (data.indexOf(keyword) > -1){
                isBlacklist = true;
            }
        });

        return isBlacklist;


    }

    render() {
        return (
            <Fragment>
                            <Card>

                                <CardHeader style={{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>

                                    <span>
                                        Manage Posts
                                    </span>

                                    <div>

                                        <Input
                                            size={'sm'}
                                            style={{width: 300, marginRight: 10, display: 'inline-block'}}
                                            placeholder={'Type here to search'}
                                            name={'pageSearch'}
                                            onChange={this.onInputChange}
                                            onKeyPress={this.enterPressed}
                                        />

                                        <Button size={'sm'} style={{marginRight: 10}} onClick={this.onSearch}>Search</Button>

                                        <Button id={'editButtonnew'}
                                                color="primary" size={'sm'}
                                                onClick={()=>{

                                                    this.props.history.push('/website/posts/edit', {
                                                        data: '',
                                                        title: '',
                                                        id: 'new',
                                                        isPost: true,
                                                        create: true,
                                                        // refresh: this.fetchData
                                                    })

                                                }}
                                        >
                                            New Post
                                        </Button>

                                        {/*<Editor*/}
                                        {/*    data={''}*/}
                                        {/*    title={''}*/}
                                        {/*    id={'new'}*/}
                                        {/*    isPost={true}*/}
                                        {/*    create={true}*/}
                                        {/*    refresh={this.fetchData}*/}
                                        {/*/>*/}

                                        &nbsp;
                                    </div>
                                </CardHeader>

                                <CardBody>


                                    <ReactTable
                                        data={this.state.postList}
                                        columns={[
                                            {
                                                Header: "Title",
                                                accessor: row => row.post_title,
                                                id: 'title'
                                            }, {
                                                Header: "Category",
                                                accessor: row => row.post_category,
                                                // accessor: row => {
                                                //     let categoryArray = [];
                                                //     row.categories.forEach((category)=> {
                                                //         categoryArray.push(this.state.categories[category])
                                                //     });
                                                //     return(categoryArray.join(', '))
                                                // },
                                                id: 'category'
                                            }, {
                                                Header: "Created",
                                                accessor: row => new Date(row.post_date).toLocaleDateString(),
                                                id: 'date',
                                                width: 150
                                            }, {
                                                Header: "Visibility",
                                                accessor: row => row.post_status==="publish"?"Public":"Hidden",
                                                id: 'visibility',
                                                width: 150
                                            }, {
                                                Header: "Actions",
                                                Cell: ({original, index})=>{
                                                    return (
                                                        <div style={{display: "flex"}}>

                                                            <Button id={'editButton' + original.id}
                                                                    disabled={this.isBlacklist(original.post_content)}
                                                                    color="primary" size={'sm'}
                                                                    onClick={()=>{

                                                                        this.props.history.push('/website/posts/edit', {
                                                                            data: original.post_content,
                                                                            title: original.post_title,
                                                                            id: original.id,
                                                                            isPost: true,
                                                                            category: original.post_category,
                                                                            // refresh: this.fetchData
                                                                        })

                                                                    }}
                                                                    style={this.props.create?{}:{width:70}}
                                                            >
                                                                {this.isBlacklist(original.post_content)?'Locked':"Edit"}
                                                            </Button>

                                                            {/*<Editor*/}
                                                            {/*    data={original.post_content}*/}
                                                            {/*    title={original.post_title}*/}
                                                            {/*    id={original.id}*/}
                                                            {/*    isPost={true}*/}
                                                            {/*    category={original.post_category}*/}
                                                            {/*    refresh={this.fetchData}*/}
                                                            {/*/>*/}
                                                            &nbsp;
                                                            <BlockUi tag="div" blocking={this.state.loading_toggle[index]}  style={{overflow:'hidden'}} loader={<Loader color="#ffffff" active type={'ball-clip-rotate'}/>}>

                                                                <Button size={'sm'} onClick={()=>{this.toggleVisibility(original.id, original.post_status==="publish", index)}}><i className="lnr lnr-eye"/></Button>
                                                            </BlockUi>&nbsp;
                                                            <BlockUi tag="div" blocking={this.state.loading_delete[index]}  style={{overflow:'hidden'}} loader={<Loader color="#ffffff" active type={'ball-clip-rotate'}/>}>

                                                            <Button size={'sm'} onClick={()=>this.delete(original.id, index)} color={'danger'}><i className="lnr lnr-cross"/></Button>
                                                            </BlockUi>&nbsp;
                                                            <a target="_blank" className={'btn btn-sm btn-secondary'} href={original.post_url}>View</a>
                                                        </div>
                                                    )
                                                },
                                                id: 'actions'
                                            }
                                        ]}
                                        defaultPageSize={10}
                                        filterable={false}
                                        loading={this.state.loading}
                                        loadingText={'Loading posts...'}
                                        className="-striped -highlight -borderless"
                                        bordered={ false }
                                        manual
                                        pages={this.state.pages}
                                        onFetchData={(state, instance) => {
                                            this.setState({loading: true, table_state: state}, ()=> {
                                                this.fetchData()
                                            });
                                        }}
                                    />
                                </CardBody>
                            </Card>
            </Fragment>
        )
    }
}
