import React, {Fragment} from 'react';
import {Route} from 'react-router-dom'

import Products from './products'
import Users from './users'

const Account = ({match, ...props}) => (
    <Fragment>
        <Route path={match.url + "/products"} render={()=> (<Products {...props}/>)}/>
        <Route path={match.url + "/users"} component={Users}/>
    </Fragment>
);

export default Account;
