import React, {Component, Fragment} from 'react';
import {Container, Row, Col, Card, CardHeader, CardBody,Button, Form,
    FormGroup, Label,
    Input} from "reactstrap";

import {toast} from "react-toastify";
import {Loader} from "react-loaders";
import BlockUi from "react-block-ui";



export default class Simple extends Component {

    constructor(props){
        super(props);
        this.state = {
            data:{},
            loading: false,
            categories: [],
            category:{}
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.onSend = this.onSend.bind(this);

    }

    componentDidMount() {

        fetch('/api/website/wordpress/categories', {
            credentials: 'same-origin',
        })
            .then(res => res.json())
            .then(resJSON=>{

                this.setState({loading: false});
                if (resJSON.success){
                    this.setState({categories: resJSON.categories});
                    resJSON.categories.forEach((item)=>{
                        if (item.name === "Newsletters"){
                            this.setState({
                                category: {...this.state.category, [String(item.id)]: item.name}
                            });
                        }
                    })

                }else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.notify('error', <span><strong>Failed!</strong> Couldn't load categories</span>)
                }

            });

    }

    onInputChange(event) {

        this.state.data[event.target.name] = event.target.value;

        if (event.target.files) {
            this.state.data['attachment'] = event.target.files[0]
        }

        this.setState({data: this.state.data});
    }

    notify(type, text) {
        toast[type](text);

    }
    onSend(){

        if (!this.state.data['from'] || !this.state.data['subject'] || this.state.data['from'] === '' || this.state.data['subject'] === ''){
            return this.notify('error', <span><strong>Failed!</strong> Please enter a from name and subject.</span>)

        }

        let formData = new FormData();

        formData.append('from', this.state.data['from']);
        formData.append('subject', this.state.data['subject']);
        formData.append('message', this.state.data['message']);
        formData.append('attachment', this.state.data['attachment']);
        formData.append('category', JSON.stringify(this.state.category));


        this.setState({loading: true});

        fetch('/api/newsletter/send/simple', {
            credentials: 'same-origin',
            method: 'post',
            // headers: {
            //     'Content-Type': "application/json"
            // },
            body: formData
        })
            .then(res => res.json())
            .then(resJSON=>{

                this.setState({loading: false});
                if (resJSON.success){

                    this.setState({
                        data:{
                            from: '',
                            subject: '',
                            message: '',
                            file: '',
                            attachment: undefined
                        }});

                    this.notify('success', <span><strong>Success!</strong> Your newsletter has been sent!</span>)
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.notify('error', <span><strong>Failed!</strong> Please try again!</span>)

                }

            })
    }

    render() {
        return (
            <Fragment>

                <Container>
                    <Row className={'mb-3'}>
                        <Col>
                            <Card>
                                <CardHeader>
                                    Send Newsletter
                                </CardHeader>


                                <CardBody>

                                    <Form>
                                        <FormGroup>
                                            <Label for="from">From</Label>
                                            <Input type="text" name="from" id="from"
                                                   placeholder="Sender Name eg. School Name Office" value={this.state.data['from']} onChange={this.onInputChange}/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="subject">Subject</Label>
                                            <Input type="text" name="subject" id="subject" value={this.state.data['subject']} onChange={this.onInputChange}/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="message">Message</Label>
                                            <Input type="textarea" name="message" id="message" rows={6}
                                                   placeholder="Your Message Here" value={this.state.data['message']} onChange={this.onInputChange}/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for={'category'}>Website Category</Label>
                                            {this.state.categories.map((item) => (
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" onChange={()=>{
                                                            if (Object.keys(this.state.category).indexOf(String(item.id)) > -1){

                                                                let old_category = this.state.category;
                                                                delete old_category[String(item.id)];
                                                                this.setState({
                                                                    category: old_category
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    category: {...this.state.category, [String(item.id)]: item.name}
                                                                });
                                                            }

                                                        }}
                                                               checked={Object.keys(this.state.category).indexOf(String(item.id)) > -1}/> {item.name}
                                                    </Label>
                                                </FormGroup>
                                            ))}
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="exampleFile">File Upload</Label>
                                            <Input type="file" name="file" id="file" value={this.state.data['file']} onChange={this.onInputChange}/>
                                        </FormGroup>
                                        <BlockUi tag="div" blocking={this.state.loading}  style={{overflow:'hidden'}}
                                                 loader={<Loader color="#ffffff" active type={'ball-clip-rotate'}/>}>
                                        <Button color="success" className="mt-1" onClick={this.onSend}>Send</Button>
                                        </BlockUi>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

