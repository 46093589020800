import React, {Fragment} from 'react';
import UploadStudents from './upload';
import UploadLeavers from './leavers';
import StudentList from './studentlist';
import ClassList from './classlist';
import Status from './status';
import {Route} from 'react-router-dom';

const Hapara = ({match}) => (
    <Fragment>
        <Route path={match.url + "/upload"} component={UploadStudents}/>
        <Route path={match.url + "/leavers"} component={UploadLeavers}/>
        <Route path={match.url + "/studentlist"} component={StudentList}/>
        <Route path={match.url + "/classlist"} component={ClassList}/>
        <Route path={match.url + "/status"} component={Status}/>
    </Fragment>
);

export default Hapara;


// export default class Hapara extends Component {
//
//     render() {
//         return (
//             <Fragment>
//                 <div>test</div>
//                 <Route path="/upload" component={UploadStudents}/>
//             </Fragment>
//         )
//     }
// }
