import React from 'react'
import Col from "reactstrap/es/Col";
import Row from "reactstrap/es/Row";
import Preview from "../Preview";
import {toast} from "react-toastify";

export default class Columns extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            elements: [[], [], []],
            settings: {},
            isTop: false,
            styleButton: null
        };

        this.addElement = this.addElement.bind(this);
        this.addMovedElement = this.addMovedElement.bind(this);
        this.removeElement = this.removeElement.bind(this);
        this.moveElement = this.moveElement.bind(this);
        this.updateElement = this.updateElement.bind(this);
        this.setStyleSettings = this.setStyleSettings.bind(this);
        this.setElementSettings = this.setElementSettings.bind(this);
        this.updateElementData = this.updateElementData.bind(this);
    }

    componentWillReceiveProps() {
        this.setState({
            elements: this.props.elements[this.props.index].data.elements,
            settings: this.props.elements[this.props.index].data.settings,
        });
    }

    addElement(type, index, data, column){
        type["settings"]= {index: index};
        type["data"]= data;
        this.state.elements[column].forEach((item)=>{
            if (item.settings.index >= index){
                item.settings.index++;
            }
        });
        this.state.elements[column].splice(index, 0, type);


        this.setState({ elements:  this.state.elements});
        this.props.updateElement(this.props.index, this.state, 0);



    }

    addMovedElement(item, index, column){
        this.state.elements[column].forEach((item)=>{
            if (item.settings.index >= index){
                item.settings.index++;
            }
        });
        this.state.elements[column].splice(index, 0, item);


        this.setState({ elements:  this.state.elements});


    }
    notify(type, text) {
        toast[type](text);

    }

    removeElement(index, column){
        const removed = this.state.elements[column].splice(index, 1);

        this.notify('success', <div>Deleted Section. <a href="#" onClick={()=>{
            this.addMovedElement(removed[0], index, column);
        }}>Click here to undo.</a></div>);

        this.state.elements[column].forEach((item)=>{
            if (item.settings.index >= index){
                item.settings.index--;
            }
        });
        this.setState({ elements:  this.state.elements});
        this.props.updateElement(this.props.index, this.state, 0);
        return removed

    }

    moveElement(from, to, column){
        this.state.elements[column].splice(to, 0, this.state.elements[column].splice(from, 1)[0]);
        this.setState({ elements:  this.state.elements});
        this.props.updateElement(this.props.index, this.state, 0);

    }

    updateElement(index, data, column){
        this.state.elements[column][index].data = data;
        this.setState({ elements:  this.state.elements});
        this.props.updateElement(this.props.index, this.state, 0);


    }

    setStyleSettings(settings, index, column){

        settings['index']= index;
        settings['column']= column;
        this.setState({settings:settings});

        this.props.setElementsForToolbox(
            this.state.elements,
            settings,
            index,
            column,
            this.setElementSettings,
            this.updateElementData
        );
        this.props.updateElement(this.props.index, this.state, 0);
    }

    setElementSettings(settings, column, index){

        this.state.elements[column][index]['settings'] = {...this.state.elements[column][index]['settings'], ...settings};
        this.setState({elements: this.state.elements});
        this.props.updateElement(this.props.index, this.state, 0);

    }

    updateElementData(){
        this.setState({elements: this.state.elements});
        this.props.updateElement(this.props.index, this.state, 0);

    }

    render() {

        let columnBorder = this.props.elements[this.props.index].settings['borderColumn'];
        let borderCenter = this.props.elements[this.props.index].settings['borderCenter'];
        let borderColor = this.props.elements[this.props.index].settings['borderColor'];

        let borderCenterWidth = columnBorder?columnBorder/2:0;
        let borderCenterColor= borderColor?borderColor:'black';

        return(
            <div onClick={(e)=>{

                if (e.target.name === 'delete') return;
                if (e.target.name !== 'columns') return;

                this.props.setStyleSettings([
                    {
                        type: 'number',
                        label: 'Padding',
                        id: 'padding',
                        value: 0
                    }, {
                        type: 'columnSize',
                        label: 'Column Size',
                        id: 'columnSize',
                        value: '6,6'
                    }, {
                        type: 'borderImage',
                        label: 'Border',
                        id: 'borderImage'
                    }
                ], this.props.index, this.props.column);

                this.props.setTab('2');

            }}
            >
                <div style={{display:'flex', justifyContent:'center', alignItems: 'center', margin:-1}}>

                    <Row style={{width:'100%'}}>
                        <Col
                            xs={this.props.elements[this.props.index].settings['columnSize']?this.props.elements[this.props.index].settings['columnSize'].split(',')[0]:6}
                            name={'columns'}
                            style={{
                                paddingLeft:0,
                                paddingRight:0,
                                borderRight:borderCenter?borderCenterWidth + 'px solid ' + borderCenterColor:null}}>

                            <Preview elements={this.state.elements[0]}
                                     setStyleSettings={this.setStyleSettings}
                                     updateElement={this.updateElement}
                                     updateElementData={this.updateElementData}
                                     setTab={this.props.setTab}
                                     removeElement={this.removeElement}
                                     moveElement={this.moveElement}
                                     addElement={this.addElement}
                                     addMovedElement={this.addMovedElement}
                                     isColumn={true}
                                     column={0}
                                     getID={this.props.getID}
                                     setElementsForToolbox={this.props.setElementsForToolbox}


                        />

                        </Col>
                        <Col xs={this.props.elements[this.props.index].settings['columnSize']?this.props.elements[this.props.index].settings['columnSize'].split(',')[1]:6}
                             style={{
                                 paddingLeft:0,
                                 paddingRight:0,
                                 borderLeft:borderCenter?borderCenterWidth + 'px solid ' + borderCenterColor:null
                             }}>
                            <Preview elements={this.state.elements[1]}
                                     setStyleSettings={this.setStyleSettings}
                                     updateElement={this.updateElement}
                                     updateElementData={this.updateElementData}
                                     setTab={this.props.setTab}
                                     removeElement={this.removeElement}
                                     moveElement={this.moveElement}
                                     addElement={this.addElement}
                                     addMovedElement={this.addMovedElement}
                                     isColumn={true}
                                     column={1}
                                     getID={this.props.getID}
                                     setElementsForToolbox={this.props.setElementsForToolbox}


                            />
                        </Col>
                    </Row>

                </div>


            </div>
        )
    }

}

