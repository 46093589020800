import React, {Component, Fragment} from 'react';
import {Container, Row, Col, Card, CardHeader, CardBody, Button, Input, Table} from "reactstrap";
import {toast} from "react-toastify";

export default class Users extends Component {

    constructor(props){
        super(props);

        this.state = {
            users: [],
            data: {},
        };

        this.onDelete = this.onDelete.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.fetchData = this.fetchData.bind(this);


    }



    fetchData(){
        fetch('/api/account/users', {credentials: 'same-origin'})
            .then(res=>res.json())
            .then(resJSON=> {

                if (resJSON.success) {
                    this.setState({
                        users: resJSON.users
                    })
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                }
            })
    }

    componentWillMount() {

        this.fetchData();

    }

    notify(type, text) {
        toast[type](text);

    }

    onInputChange(event) {


        this.state.data[event.target.name] = event.target.value;

        this.setState({data: this.state.data});
    }

    onDelete(email){

        fetch(`/api/account/users/${email}/delete`, {
            credentials: 'same-origin',
        })
            .then(res => res.json())
            .then(resJSON=>{

                if (resJSON.success){
                    this.fetchData();
                    this.notify('success', <span><strong>Success!</strong> User Deleted!</span>)
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.notify('error', <span><strong>Failed!</strong> Please try again!</span>)

                }

            })

    }

    onAdd(){
        fetch('/api/account/users/add', {
            credentials: 'same-origin',
            method: 'post',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({
                email: this.state.data['email'],
                name: this.state.data['name'],
            })
        })
            .then(res => res.json())
            .then(resJSON=>{

                if (resJSON.success){
                    this.fetchData();
                    this.setState({data:{email:'', name:''}});
                    this.notify('success', <span><strong>Success!</strong> User Added!</span>)
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.notify('error', <span><strong>Failed!</strong> Please try again!</span>)

                }

            })
    }

    render() {
        return (
            <Fragment>
                <Container>
                    <Row className={'mb-3'}>
                        <Col>
                            <Card>
                                <CardHeader>
                                    Users
                                </CardHeader>


                                <CardBody style={{padding:0}}>


                                    <Table striped hover responsive>
                                        <thead>
                                        <tr>
                                            <th>Email</th>
                                            <th>Name</th>
                                            <th>Manage</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {this.state.users.map((item, index)=>(

                                            <tr>
                                                <td>{item.email}</td>
                                                <td>{item.name}</td>
                                                <td>
                                                    <Button color={'danger'} onClick={()=>{this.onDelete(item.email)}}>Unauthorise</Button>
                                                </td>
                                            </tr>

                                        ))}

                                        <tr id={'authorise-user-row'}>

                                            <td>
                                                <Input placeholder="Email of user you would like to authorise"
                                                       title="email" name="email" value={this.state.data['email']} onChange={this.onInputChange} />
                                            </td>

                                            <td>
                                                <Input placeholder="Name of user you would like to authorise"
                                                       title="name" name="name" value={this.state.data['name']} onChange={this.onInputChange} />
                                            </td>
                                            <td>
                                                <Button color={'primary'} onClick={this.onAdd}>Authorise</Button>
                                            </td>


                                        </tr>


                                        </tbody>
                                    </Table>


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
