import React, {Component, Fragment} from 'react';

import {
    Button,
    Row, Col,
    Card, CardBody,
    CardTitle, Container,
    CardHeader
} from 'reactstrap';

import PerfectScrollbar from 'react-perfect-scrollbar';

import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';


export default class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            updates: []
        };
    }
    componentDidMount() {

        fetch('/api/support/updates', {credentials: 'same-origin'})
            .then(res=>res.json())
            .then(resJSON=>{
                if (resJSON.success) {
                    this.setState({
                        updates: resJSON.data
                    });
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                }
            });
    }

    formatDate(date) {

        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear().toString().substr(-2);

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');

    }

    render() {
        return (
            <Fragment>

                <Container fluid>

                    <Row>
                        <Col lg="8">
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <CardTitle>Quick Links</CardTitle>
                                    <div className="grid-menu grid-menu-3col">
                                        <Row className="no-gutters">

                                            <Col xl="4" sm="6">
                                                <Button
                                                    onClick={()=>{
                                                        this.props.history.push('/website/posts');
                                                    }}
                                                    className="btn-icon-vertical btn-square btn-transition"
                                                    outline color="link">
                                                    <i className="pe-7s-browser btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                                    Website
                                                </Button>
                                            </Col>
                                            <Col xl="4" sm="6">
                                                <Button
                                                    onClick={()=>{
                                                        this.props.history.push('/app/managestaff');
                                                    }}
                                                    className="btn-icon-vertical btn-square btn-transition"
                                                    outline color="link">
                                                    <i className="pe-7s-phone btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                                    App
                                                </Button>
                                            </Col>
                                            <Col xl="4" sm="6">
                                                <Button
                                                    onClick={()=>{
                                                        this.props.history.push('/newsletter/manage/list');
                                                    }}
                                                    className="btn-icon-vertical btn-square btn-transition"
                                                    outline color="link">
                                                    <i className="pe-7s-news-paper btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                                    Newsletter
                                                </Button>
                                            </Col>
                                            <Col xl="4" sm="6">
                                                <Button
                                                    onClick={()=>{
                                                        this.props.history.push('/hapara/studentlist');
                                                    }}
                                                    className="btn-icon-vertical btn-square btn-transition"
                                                    outline color="link">
                                                    <i className="pe-7s-cloud btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                                    Learning in the Cloud
                                                </Button>
                                            </Col>

                                            <Col xl="4" sm="6">
                                                <Button
                                                    onClick={()=>{
                                                        this.props.history.push('/account/products');
                                                    }}
                                                    className="btn-icon-vertical btn-square btn-transition"
                                                    outline color="link">
                                                    <i className="pe-7s-user btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                                    Account
                                                </Button>
                                            </Col>
                                            <Col xl="4" sm="6">
                                                <Button
                                                    onClick={()=>{
                                                        this.props.history.push('/support/tickets');
                                                    }}
                                                    className="btn-icon-vertical btn-square btn-transition"
                                                    outline color="link">
                                                    <i className="pe-7s-help1 btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                                    Support
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col sm="12" lg="4">
                            <Card className="card-hover-shadow-2x mb-3">
                                <CardHeader className="card-header-tab">
                                    <div
                                        className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                        <i className="header-icon pe-7s-chat icon-gradient bg-amy-crisp"> </i>
                                        Updates from KiwiSchools
                                    </div>
                                </CardHeader>
                                <div>
                                    <PerfectScrollbar>
                                        <div className="p-4">
                                            <VerticalTimeline layout="1-column">

                                                {console.log(this.state)}

                                                {this.state.updates.map((update)=>(
                                                    <VerticalTimelineElement
                                                        className="vertical-timeline-item"
                                                        icon={<i
                                                            className="badge badge-dot badge-dot-xl badge-success"> </i>}
                                                        date={this.formatDate(update.published_at * 1000)}
                                                    >
                                                        <a target="_blank" rel="noopener noreferrer" href={`https://support.kiwischools.co.nz/en/announcements/article/${update.slug}`}>
                                                            <h4 className="timeline-title">{update.title}</h4>
                                                        </a>
                                                        <p>
                                                            {update.excerpt}
                                                        </p>
                                                    </VerticalTimelineElement>
                                                ))}


                                            </VerticalTimeline>
                                        </div>
                                    </PerfectScrollbar>
                                </div>
                            </Card>
                        </Col>

                    </Row>

                </Container>

            </Fragment>
        )
    }
}
