import React, {Component, Fragment} from 'react';
import {Container, Row, Col, Card, CardHeader, CardBody, Button, FormGroup, CustomInput,
    Table} from "reactstrap";
import {toast} from "react-toastify";

export default class ClassList extends Component {

    constructor(props){
        super(props);

        this.state = {
            classes: [],
            folders: []
        };

        this.onSave = this.onSave.bind(this);
    }

    componentWillMount() {

        fetch('/api/hapara/manage/classes', {credentials: 'same-origin'})
            .then(res=>res.json())
            .then(resJSON=> {
                if (resJSON.success) {
                    this.setState({
                        classes: resJSON.classes,
                        folders: resJSON.folders
                    })
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.props.history.push('/hapara/upload');
                }
            })

    }

    notify(type, text) {
        toast[type](text);

    }

    onInputChange(event) {

        let fieldMap = {
            'email': 'Email',
            'folders': 'Subject Folders',
            'class': 'Class',
        };

        this.state.data[fieldMap[event.target.name]] = event.target.value;

        this.setState({data: this.state.data});
    }

    onSave(){
        let updateDetails = {
            classes: this.state.classes
        };

        let failed = false;

        updateDetails.classes.map(item=>Object.keys(item).map(field=>{
            if (item[field] === ''){
                failed = true;
            }
        }));

        if (failed){
            this.notify('error', <span><strong>Failed!</strong> All fields must be filled in!</span>);
            return;
        }

        fetch('/api/hapara/upload/classmanagement', {
            credentials: 'same-origin',
            method: 'post',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify(updateDetails)
        })
            .then(res => res.json())
            .then(resJSON=>{

                if (resJSON.success){

                    this.notify('success', <span><strong>Success!</strong> Your changes are being reviewed by KiwiSchools. We will notify you by email once your changes have been actioned.</span>)
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.notify('error', <span><strong>Failed!</strong> Please try again!</span>)

                }

            })
    }

    render() {
        return (
            <Fragment>
                <Container>
                    <Row className={'mb-3'}>
                        <Col>
                            <Card>
                                <CardHeader>
                                    Class List
                                </CardHeader>


                                <CardBody style={{padding:0}}>


                                    <Table striped hover responsive>
                                        <thead>
                                        <tr>
                                        <th>Class Code</th>
                                        <th>Class Name</th>
                                        <th>Teachers</th>
                                        <th>Folders</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {this.state.classes.map((item, index)=>(

                                            <tr className="classList">
                                                <td className="classCode">{item.Mailbox}</td>
                                                <td>
                                                    <input title="className" name={item.Mailbox + "-formatted"}
                                                           value={item.Name}
                                                           className="form-control className"
                                                           onChange={(e)=>{
                                                               this.state.classes[index]['Name'] = e.target.value;
                                                               this.setState({classes: this.state.classes})
                                                           }}

                                                    />
                                                </td>
                                                <td>
                                                    <input title="teachers" name={item.Mailbox + "-teachers"}
                                                           value={item.Teachers} placeholder="eg. jdoe, vsmith"
                                                           className="form-control teachers"
                                                           onChange={(e)=>{
                                                               this.state.classes[index]['Teachers'] = e.target.value;
                                                               this.setState({classes: this.state.classes})
                                                           }}
                                                    />
                                                </td>
                                                <td style={{maxWidth:270}}>
                                                    <FormGroup>
                                                        <div>

                                                            {this.state.folders.map((folder, indexF)=>(
                                                                <span style={{minWidth: 110, display:'inline-block'}}>
                                                                <CustomInput type="checkbox"
                                                                             id={item.Mailbox + '-' + folder}
                                                                             label={folder.replace('*', 'All Files')}
                                                                             inline
                                                                             checked={item['Subject Folders'].split(',').indexOf(folder) > -1}
                                                                             onChange={(e)=>{
                                                                                 if (e.target.checked){
                                                                                     let foldersSplit = this.state.classes[index]['Subject Folders'].split(',');
                                                                                     foldersSplit.push(folder);
                                                                                     this.state.classes[index]['Subject Folders'] = foldersSplit.join(',');
                                                                                     this.setState({classes: this.state.classes})

                                                                                 }else {
                                                                                     let foldersSplit = this.state.classes[index]['Subject Folders'].split(',');
                                                                                     foldersSplit.splice(foldersSplit.indexOf(folder), 1);
                                                                                     this.state.classes[index]['Subject Folders'] = foldersSplit.join(',');

                                                                                     this.setState({classes: this.state.classes})

                                                                                 }
                                                                             }}
                                                                />
                                                                    {indexF%3===2?<br/>:null}
                                                                    </span>
                                                            ))}
                                                        </div>
                                                    </FormGroup>
                                                </td>
                                            </tr>

                                        ))}


                                        </tbody>
                                    </Table>

                                    <Button color={'success'} style={{float:'right', marginBottom:20, marginRight:20}} onClick={this.onSave}>Save Changes</Button>


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
