import React, {useState} from 'react'
import {useDrag} from "react-dnd";

import Wrapper from "./Wrapper";
import Button from "reactstrap/es/Button";



export default function Container(props){

    const [{ opacity }, drag, preview] = useDrag({
        item: { type: 'element' },
        collect: monitor => ({
            opacity: monitor.isDragging() ? 0.5 : 1,
        }),
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            // if (dropResult.index === props.index-1) return;
            // if (props.index === 0) return;
            if (item && dropResult){

                if(props.parent === dropResult.parent && props.index < dropResult.index){
                    const removed = props.removeElement(props.index, props.column);
                    dropResult.addMovedElement(removed[0], dropResult.index-1, dropResult.column);
                } else {
                    const removed = props.removeElement(props.index, props.column);
                    dropResult.addMovedElement(removed[0], dropResult.index, dropResult.column);
                }



            }
        }
    });


    const [hoverStyle, setHoverStyle] = useState({
        // border: 'solid 1px gray',
        // boxSizing: 'border-box',
    });
    const [hoverTools, setHoverTools] = useState({display: 'none'});


    function onHover(isHover){
        if (isHover){
            setHoverStyle({boxShadow:'inset 0px 0px 0px 1px gray'});
            setHoverTools({display: 'block'});


        } else {
            setHoverStyle({boxShadow:'inset 0px 0px 0px 0px gray'});
            setHoverTools({display: 'none'});

        }
    }

    function onRemove() {
        props.removeElement(props.index, props.column);
    }

    function onStyle() {
        props.setStyleSettings([
            {
                type: 'number',
                label: 'Padding',
                id: 'padding',
                value: 0
            }, {
                type: 'columnSize',
                label: 'Column Size',
                id: 'columnSize',
                value: '6,6'
            },
            {
                type: 'borderImage',
                label: 'Border',
                id: 'borderColumn'
            },
            {
                type: 'color',
                label: 'Background Colour',
                id: 'backgroundColor',
                value: '#ffffff'
            }
        ], props.index, props.column);

        props.setTab('2');

    }

    // noinspection JSUnresolvedFunction
    return(


        <div style={{...hoverStyle, ...props.settings, position: 'relative'}}
             onMouseEnter={()=>{onHover(true)}}
             onMouseLeave={()=>{onHover(false)}}
             ref={preview}>

             {props.type === 'columns'?

                 <div style={{...hoverTools, ...{position:'absolute', top:0, left:2, marginLeft:'100%', zIndex:999}, opacity}}
                      ref={drag}>
                     <Button name={'delete'} className={'btn-secondary btn-sm'} style={{marginBottom:2}}>
                         <i className={'lnr-move'}/>
                     </Button><br/>
                     <Button name={'style'} className={'btn-secondary btn-sm'} onClick={onStyle}  style={{marginBottom:2}}>
                         <i className={'lnr-pencil'}/>
                     </Button><br/>
                     <Button name={'delete'} className={'btn-danger btn-sm'} onClick={onRemove}>
                         <i className={'lnr-cross'}/>
                     </Button>
                 </div>

                 :
                 <div style={{...hoverTools, ...{position:'absolute', top:-28, right:0, zIndex:999}, opacity}}
                      ref={drag}>
                     <Button name={'delete'} className={'btn-secondary btn-sm'} style={{marginRight:2}}>
                         <i className={'lnr-move'}/>
                     </Button>
                     <Button name={'delete'} className={'btn-danger btn-sm'} onClick={onRemove}>
                         <i className={'lnr-cross'}/>
                     </Button>
                 </div>



             }



            {props.index?null: <Wrapper index={props.index} addElement={props.addElement} column={props.column} isColumn={props.isColumn} addMovedElement={props.addMovedElement} parent={props.parent}/>}

            {
                React.cloneElement(
                props.element,
                {
                    settings: props.settings,
                    updateElement: props.updateElement,
                    updateElementData: props.updateElementData,
                    elements: props.elements,
                    index: props.index,
                    setStyleSettings: props.setStyleSettings,
                    setTab: props.setTab,
                    removeElement: props.removeElement,
                    addMovedElement: props.addMovedElement,
                    column:props.column,
                    setElementsForToolbox: props.setElementsForToolbox,
                    getID: props.getID
                }
            )}

            <Wrapper index={props.index + 1} addElement={props.addElement} column={props.column} bottom={true} isColumn={props.isColumn} addMovedElement={props.addMovedElement} parent={props.parent}/>


        </div>
    )

}

