import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Input from "reactstrap/es/Input";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";

import BlockUi from 'react-block-ui';
import {Loader} from "react-loaders";


export default class NewMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            data: {
                'fullname': '',
                'email': ''
            },
            loading: false,
        };

        this.toggle = this.toggle.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }



    toggle() {
        this.setState({
            modal: !this.state.modal,
            data: {
                'fullname': '',
                'email': ''
            }
        });
    }

    onInputChange(event) {

        this.state.data[event.target.name] = event.target.value;

        this.setState({data: this.state.data});
    }

    onSave(){

        let updateDetails = this.state.data;

        let failed = false;

        Object.keys(updateDetails).map(key=>{
            if (updateDetails[key] === ''){
                failed = true;
            }
        });

        if (failed){
            this.props.notify('error', <span><strong>Failed!</strong> All fields must be filled in!</span>);
            return;
        }

        this.setState({loading: true});

        fetch('/api/newsletter/manage/add', {
            credentials: 'same-origin',
            method: 'post',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify(updateDetails)
        })
            .then(res => res.json())
            .then(resJSON=>{
                this.setState({loading: false});
                this.toggle();

                if (resJSON.success){

                    this.props.updateList(resJSON.member);

                    this.props.notify('success', <span><strong>Success!</strong> Member has been added to your mailing list</span>)
                } else {
                    this.props.notify('error', <span><strong>Failed!</strong> Please try again!</span>);

                    if (resJSON['isAuthFailed']) {
                        if (process.env.NODE_ENV === 'development'){
                            window.location.href = 'http://localhost:3001/api/login';
                        } else {
                            window.location.href = 'https://central.kiwischools.co.nz/api/login';
                        }
                    }

                }

            })

    }

    render() {


        return (
            <span className="d-inline-block">

                <Button color={'success'} onClick={this.toggle}>Add New Member</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="xl">
                    <ModalHeader toggle={this.toggle}>Add New Member</ModalHeader>
                    <ModalBody>

                        <FormGroup>
                            <Label for={'fullname'}>Full Name</Label>
                            <Input name={'fullname'} value={this.state.data['fullname']} onChange={this.onInputChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for={'email'}>Email</Label>
                            <Input name={'email'} value={this.state.data['email']} onChange={this.onInputChange}/>
                        </FormGroup>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="link" onClick={this.toggle}>Cancel</Button>
                        <BlockUi tag="div" blocking={this.state.loading}  style={{overflow:'hidden'}}
                                 loader={<Loader color="#ffffff" active type={'ball-clip-rotate'}/>}>
                        <Button color="primary" onClick={this.onSave}>Add</Button>
                        </BlockUi>
                    </ModalFooter>
                </Modal>
            </span>
        );
    }
}
