import React, {Fragment} from 'react';
import {Route} from 'react-router-dom'


import List from './list';

const Manage = ({match}) => (
    <Fragment>


        <Route path={match.url + "/list"} component={List}/>
    </Fragment>
);

export default Manage;
// }
