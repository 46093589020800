import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Input from "reactstrap/es/Input";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";


export default class StudentEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            data: this.props.data
        };
        this.toggle = this.toggle.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }



    toggle() {
        this.setState({
            modal: !this.state.modal,
            data: this.props.data
        });
    }

    onInputChange(event) {

        let fieldMap = {
            'firstname': 'First Name',
            'lastname': 'Last Name',
            'class': 'Class',
            'email': 'Email'
        };

        this.state.data[fieldMap[event.target.name]] = event.target.value;

        this.setState({data: this.state.data});
    }

    onSave(){

        let updateDetails = {
            "firstname": this.state.data['First Name'],
            "lastname": this.state.data['Last Name'],
            "class": this.state.data['Class'],
            "email": this.state.data['Email'],
            index: this.props.index
        };

        let failed = false;

        Object.keys(updateDetails).map(key=>{
           if (updateDetails[key] === ''){
               failed = true;
           }
        });

        if (failed){
            this.props.notify('error', <span><strong>Failed!</strong> All fields must be filled in!</span>);
            return;
        }

        fetch('/api/hapara/manage/updatestudent', {
            credentials: 'same-origin',
            method: 'post',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify(updateDetails)
        })
            .then(res => res.json())
            .then(resJSON=>{
                this.toggle();

                if (resJSON.success){

                    this.props.updateStudentList(this.props.index, updateDetails);

                    this.props.notify('success', <span><strong>Success!</strong> You student addition request is pending verification from KiwiSchools. We will notify you by email once the student is ready to go.</span>)
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.props.notify('error', <span><strong>Failed!</strong> Please try again!</span>)

                }

            })

    }

    render() {


        return (
            <span className="d-inline-block">

                <Button className='btn-sm' onClick={this.toggle}>{this.props.label}</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="xl">
                    <ModalHeader toggle={this.toggle}>Edit Student</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for={'firstname'}>First Name</Label>
                            <Input name={'firstname'} value={this.state.data['First Name']} onChange={this.onInputChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for={'lastname'}>Last Name</Label>
                            <Input name={'lastname'} value={this.state.data['Last Name']} onChange={this.onInputChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for={'email'}>Email Address</Label>
                            <Input name={'email'} value={this.state.data['Email']} onChange={this.onInputChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for={'class'}>Class</Label><br/>

                            {this.props.classes.map((item)=>(
                                <label className="radio-inline">
                                    <input type="radio" name="class" value={item} checked={this.state.data['Class'] === item} onChange={this.onInputChange}/>&nbsp;
                                    {item}&nbsp;&nbsp;
                                </label>

                                ))}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="link" onClick={this.toggle}>Cancel</Button>
                        <Button color="primary" onClick={this.onSave}>Save</Button>{' '}
                    </ModalFooter>
                </Modal>
            </span>
        );
    }
}
