import React, {Fragment} from 'react';
import {Route, Switch} from 'react-router-dom'

import Simple from './simple';
import Builder from './builder';
import SendPage from './Components/SendPage';
import InactiveProduct from "../../Main/Components/InactiveProduct";

const Send = ({url, ...props}) => (
    <Fragment>

        <Switch>

        <Route path={url + "/send/simple"} component={Simple}/>
        <Route path={url + "/send/builder/confirm"} component={SendPage}/>

        <Route path={url + "/send/builder"} render={()=> (
            props.account.builder
                ? <Builder {...props}/>
                : <InactiveProduct/>
            )}/>
        </Switch>
    </Fragment>
);

export default Send;
// }
