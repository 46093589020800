import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Input from "reactstrap/es/Input";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import Row from "reactstrap/es/Row";
import Col from "reactstrap/es/Col";


export default class NewStudent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            data: {
                'publicfirstname': '',
                'publiclastname': '',
                'class': ''
            }
        };

        this.toggle = this.toggle.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }



    toggle() {
        this.setState({
            modal: !this.state.modal,
            data: {
                'publicfirstname': '',
                'publiclastname': '',
                'class': ''
            }
        });
    }

    onInputChange(event) {

        this.state.data[event.target.name] = event.target.value;

        this.setState({data: this.state.data});
    }

    onSave(){

        let updateDetails = this.state.data;

        let failed = false;

        Object.keys(updateDetails).map(key=>{
            if (updateDetails[key] === ''){
                failed = true;
            }
        });

        if (failed){
            this.props.notify('error', <span><strong>Failed!</strong> All fields must be filled in!</span>);
            return;
        }

        fetch('/api/hapara/manage/students/add', {
            credentials: 'same-origin',
            method: 'post',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify(updateDetails)
        })
            .then(res => res.json())
            .then(resJSON=>{
                this.toggle();

                if (resJSON.success){

                    this.props.updateStudentList(resJSON.student);

                    this.props.notify('success', <span><strong>Success!</strong> You student addition request is pending verification from KiwiSchools. We will notify you by email once the student is ready to go.</span>)
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.props.notify('error', <span><strong>Failed!</strong> Please try again!</span>)

                }

            })

    }

    render() {


        return (
            <span className="d-inline-block">

                <Button color={'success'} onClick={this.toggle}>Add New Student</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="xl">
                    <ModalHeader toggle={this.toggle}>Add New Student</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={6}>
                                <h5>Public Display Settings</h5>
                                <p>This will be the name associated with their account</p>
                                    <FormGroup>
                                        <Label for={'publicfirstname'}>First Name</Label>
                                        <Input name={'publicfirstname'} value={this.state.data['publicfirstname']} onChange={this.onInputChange}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for={'publiclastname'}>Last Name</Label>
                                        <Input name={'publiclastname'} value={this.state.data['publiclastname']} onChange={this.onInputChange}/>
                                    </FormGroup>
                            </Col>
                            <Col md={6}>
                                <h5>Email Generation Information</h5>
                                <p>This will be used to generate their username. (Please enter info again if duplicate)</p>
                                {this.props.fields.map((item)=>(
                                    <FormGroup>
                                        <Label for={item}>{item}</Label>
                                        <Input name={item} value={this.state.data[item]} onChange={this.onInputChange}/>
                                    </FormGroup>
                                ))}
                            </Col>
                        </Row>



                        <FormGroup>
                            <Label for={'class'}>Class</Label><br/>

                            {this.props.classes.map((item)=>(
                                <label className="radio-inline">
                                    <input type="radio" name="class" value={item} checked={this.state.data['class'] === item} onChange={this.onInputChange}/>&nbsp;
                                    {item}&nbsp;&nbsp;
                                </label>

                            ))}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="link" onClick={this.toggle}>Cancel</Button>
                        <Button color="primary" onClick={this.onSave}>Add</Button>{' '}
                    </ModalFooter>
                </Modal>
            </span>
        );
    }
}
