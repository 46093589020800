import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import IframeResizer from "iframe-resizer-react";
import {Loader} from "react-loaders";


export default class StudentEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            data: this.props.data ? this.props.data : [],
            ticketData: this.props.ticketData ? this.props.ticketData : {},
            loading: true,
        };
        this.toggle = this.toggle.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    toggle() {

        this.props.toggle();

        this.setState({
            modal: !this.state.modal,
            data: this.props.data
        });
    }

    onInputChange(event) {

        this.state.data[event.target.name] = event.target.value;

        this.setState({data: this.state.data});
    }

    render() {

        let url = 'https://beta.kiwischools.co.nz/api/support/ticket/';

        if (process.env.NODE_ENV === 'development') {
            url = 'http://localhost:3001/api/support/ticket/';
        }
        return (
            <Modal isOpen={true} toggle={this.toggle} size="xl">

                <ModalHeader toggle={this.toggle}>Ticket: {this.state.ticketData.subject} (#{this.state.ticketData.number})</ModalHeader>
                <ModalBody>

                    {this.state.loading?<div style={{display: 'flex', justifyContent: 'center', marginTop: 100}}>
                        <Loader active type={'ball-scale-multiple'}/>
                    </div>:null}


                    <IframeResizer style={{border:0, minWidth: '100%', minHeight: '100vh'}} src={url + this.state.ticketData.number} checkOrigin={false} onLoad={()=>{this.setState({loading: false})}} />


                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.toggle}>Close</Button>{' '}
                </ModalFooter>
            </Modal>
        );
    }
}
