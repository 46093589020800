import React, {Fragment} from 'react';
import {Route} from 'react-router-dom'

import Manage from './Manage'
import Send from './Send'

const Newsletter = ({match, ...props}) => (
    <Fragment>
        <Route path={match.url + "/manage"} component={Manage}/>
        <Route path={match.url + "/send"} render={()=>(<Send {...props} {...match} />)}/>
    </Fragment>
    )
;

export default Newsletter;
