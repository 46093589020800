import {ChromePicker} from "react-color";
import React from "react";

export default class NewMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: false
        };
    }

    render(){
        return (

            <div>

                <div
                    style={{
                        height: 20,
                        width: 20,
                        background: this.props.color,
                        border: 'solid 1px',
                        borderRadius: 5,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}

                    onClick={()=>{
                        this.setState({display: !this.state.display})
                    }}
                >
                    <a href={'#'} style={{display: this.state.display?'block':'none', textDecoration:'none', color:'darkred', userSelect: 'none'}}>X</a>
                </div>

                <div style={{position: 'absolute', zIndex: 999, display: this.state.display?'block':'none'}} ref={this.setWrapperRef} className={'picker'}>
                    <div onClick={()=>{
                        this.setState({display: !this.state.display})
                    }} style={{position: 'fixed', top: 0, left: 0, bottom: 0, right: 0}}>test</div>
                    <div>
                    <ChromePicker
                        color={this.props.color} onChangeComplete={ (color)=>{

                            let output = {};
                            output[this.props.property] = color.hex;
                        this.props.setElementSettings(output, this.props.column, this.props.index)
                    }}
                        width={'80%'}
                    />
                    </div>
                </div>
            </div>


        )
    }

}

