import React, {Fragment} from 'react';
import {Route} from 'react-router-dom'


import NewTicket from './newticket';
import Tickets from './tickets';
import Help from './help';
import Knowledgebase from './knowledgebase';
import Account from "../Account";

const Support = ({match, ...props}) => (
    <Fragment>
        <Route path={match.url + "/tickets"} component={Tickets}/>
        <Route path={match.url + "/new"} component={NewTicket}/>
        <Route path={match.url + "/help"} render={(propsInner) => (
            <Help openTour={props.openTour} tours={props.tours} />

        )}/>
        <Route path={match.url + "/knowledgebase"} component={Knowledgebase}/>

    </Fragment>
);

export default Support;
