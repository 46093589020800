export const DashboardNav = [
    ];

export const MainNav = [
    {
        icon: 'pe-7s-keypad',
        label: 'Home',
        to: '/home',
    },
    {
        icon: 'pe-7s-browser',
        label: 'Website',
        id: 'menu-website',
        content: [
            {
                label: 'Manage Posts',
                to: '/website/posts',
                id: 'menu-website-posts',
            },
            {
                label: 'Manage Pages',
                to: '/website/pages',
            },
            {
                label: 'Manage Galleries',
                to: '/website/galleries',
            },
            {
                label: 'Manage Staff List',
                to: '/website/managestaff',
            }
        ],
    },
    {
        icon: 'pe-7s-phone',
        label: 'App',
        id: 'menu-app',
        content: [
            {
                label: 'Send Notification',
                to: '/app/send',
                id: 'menu-app-send',
            },
            {
                label: 'Manage Staff List',
                to: '/app/managestaff',
            },
        ]

    },
    {
        icon: 'pe-7s-news-paper',
        label: 'Newsletter',
        content: [
            {
                label: 'Manage Mailing List',
                to: '/newsletter/manage/list',
            },
            // {
            //     label: 'Add New Member',
            //     to: '/newsletter/manage/members',
            // },
            {
                label: 'Simple Newsletter',
                to: '/newsletter/send/simple',
            },
            {
                label: 'Newsletter Builder',
                to: '/newsletter/send/builder',
            },
        ],
    },
    {
        icon: 'pe-7s-cloud',
        label: 'Learning in the Cloud',
        content: [
            {
                label: 'Student List Upload',
                to: '/hapara/upload',
            },
            {
                label: 'Leavers List Upload',
                to: '/hapara/upload',
            },
            {
                label: 'Manage Students',
                to: '/hapara/studentlist',
            },
            {
                label: 'Manage Classes',
                to: '/hapara/classlist',
            },
            {
                label: 'Status',
                to: '/hapara/status',
            },
        ],
    },
    {
        icon: 'pe-7s-user',
        label: 'Account',
        id: 'menu-account',
        content: [
            {
                label: 'My Products',
                to: '/account/products',

            },
            {
                label: 'Manage Users',
                to: '/account/users',
                id: 'menu-account-users',

            },
        ],
    },
    {
        icon: 'pe-7s-help1',
        label: 'Support',
        id: 'menu-support',
        content: [
            {
                label: 'My Tickets',
                to: '/support/tickets',
                id: 'menu-support-view',
            },
            {
                label: 'New Support Ticket',
                to: '/support/new',
                id: 'menu-support-ticket',
            },
            {
                label: 'Tutorials',
                to: '/support/help',
            },
            {
                label: 'Knowledgebase',
                to: '/support/knowledgebase',
            },
        ]
    },
];

export const AccountNav = [

];

