import React, {Component, Fragment} from 'react';
import {
    Row, Col, Card, CardBody, Button, Form,
    FormGroup, Label, Select,
    Input, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import classnames from "classnames";
import Block from "./Block";
import Color from "./Color";

import PerfectScrollbar from 'react-perfect-scrollbar';
import Send from "./Send";
import OnlyWebsite from "./OnlyWebsite";

import {getInputType, blocks} from "./BlockManager";


export default class Toolbox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            values:{}
        };

    }

    handleChangeNumber(id, value){
        let settings = {};
        settings[id] = value.target.value?value.target.value:0;

        if (id.indexOf('border') > -1){
            let color = this.props.elements[this.props.column][this.props.index].settings['borderColor']?this.props.elements[this.props.column][this.props.index].settings['borderColor']:'black';
        settings[id] = settings[id] + 'px solid ' + color;
        } else {
            settings[id] = parseInt(settings[id]);
        }


        this.props.setElementSettings(settings, this.props.column);

    }

    render() {
        return (
            <Card className="main-card mb-3" style={{height:'calc(100vh - 180px)', maxHeight:'calc(100vh - 180px)', overflow:'auto'}}>
                <CardBody>
                    {/*<CardTitle>Tool Panel</CardTitle>*/}
                    <Nav tabs="true" justified>
                        <NavItem>
                            <NavLink href="javascript:void(0);"
                                     className={classnames({active: this.props.activeTab === '1'})}
                                     onClick={() => {
                                         this.props.setTab('1');
                                     }}
                            >
                                Blocks
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="javascript:void(0);"
                                     className={classnames({active: this.props.activeTab === '2'})}
                                     onClick={() => {
                                         this.props.setTab('2');
                                     }}
                            >
                                Style
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="javascript:void(0);"
                                     className={classnames({active: this.props.activeTab === '3'})}
                                     onClick={() => {
                                         this.props.setTab('3');
                                     }}
                            >
                                Send
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.props.activeTab}>
                        <TabPane tabId="1">

                            <div style={{display: 'flex', flexWrap: 'wrap'}}>

                            {
                                Object.keys(blocks).map((key, index) => {

                                    const block = blocks[key];

                                    return (
                                            <div style={{display:'flex', justifyContent:'center', width: '100%', flex: '0 50%', paddingBottom: 10, paddingTop: 10}}>
                                                <Block label={block['block'].text} icon={block['block'].icon} type={block['block'].type}/>
                                            </div>

                                    )


                                })

                            }
                            </div>

                        </TabPane>
                        <TabPane tabId="2">
                            <PerfectScrollbar>
                            <Form style={{height:'calc(100vh - 270px)'}}>

                            {this.props.settings.map((setting)=>{

                                if (!this.props.elements[this.props.column][this.props.index]) return;

                                let value = this.props.elements[this.props.column][this.props.index].settings[setting.id]?this.props.elements[this.props.column][this.props.index].settings[setting.id]:'';

                                return getInputType(this.props, value, setting);

                            }
                            )}
                            </Form>
                            </PerfectScrollbar>

                        </TabPane>
                        <TabPane tabId="3">
                            <p>Templates:</p>

                            <FormGroup>
                                <Input type="select" name="select" id="templateSelect">
                                    {this.props.templates.map(item=>(
                                        <option value={item.id}>{item.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>

                            <Row>
                                <Col sm={6}>
                                    <Button block onClick={()=>this.props.loadTemplate()} style={{display:'flex', alignItems: 'center'}}>
                                        <i className={'lnr-enter-down'} style={{fontSize:30, marginRight:20}}/>
                                        Load
                                    </Button>
                                </Col>

                                <Col>
                                    <Button block onClick={()=>{

                                        var name = prompt("Please enter a name", "New Newsletter");

                                        fetch('/api/newsletter/send/builder/new', {
                                            credentials: 'same-origin',
                                            method: 'post',
                                            headers: {
                                                'Content-Type': "application/json"
                                            },
                                            body: JSON.stringify({name: name})
                                        })
                                            .then(res=>res.json())
                                            .then(resJSON=>{

                                                this.props.save()
                                                this.props.load()

                                            })
                                    }} style={{display:'flex', alignItems: 'center'}}>
                                        <i className={'lnr-file-add'} style={{fontSize:30, marginRight:20}}/>
                                        <span>New</span>
                                    </Button>
                                </Col>
                            </Row>

                            <br/>

                            <p>Editing Options:</p>

                            <Row>
                                <Col sm={6}>
                                    <Button block onClick={()=>this.props.save(false)} style={{display:'flex', alignItems: 'center'}}>
                                        <i className={'lnr-cloud-sync'} style={{fontSize:30, marginRight:20}}/>
                                        Save
                                    </Button>
                                </Col>

                                <Col>
                                    <Button block onClick={()=>{

                                        fetch('/api/newsletter/send/builder/build', {
                                            credentials: 'same-origin',
                                            method: 'post',
                                            headers: {
                                                'Content-Type': "application/json"
                                            },
                                            body: JSON.stringify({build: this.props.prepare()})
                                        })
                                            .then(res=>res.json())
                                            .then(resJSON=>{

                                                let win = window.open("", "Newsletter Preview");
                                                win.document.body.innerHTML = resJSON.html;

                                            })
                                    }} style={{display:'flex', alignItems: 'center'}}>
                                        <i className={'lnr-file-empty'} style={{fontSize:30, marginRight:20}}/>
                                        <span>Preview</span>
                                    </Button>
                                </Col>
                            </Row>

                            <br/>
                            <p>Mailing Options:</p>

                            <Row>
                                <Col sm={6}>


                                    <Button style={{display:'flex', alignItems: 'center'}} block color={'success'} onClick={()=>{
                                        this.props.save(true);
                                        this.props.history.push('/newsletter/send/builder/confirm', {
                                            build: this.props.prepare(),
                                            test: true
                                        });
                                    }}>
                                        <i className={'lnr-user'} style={{fontSize:30, marginRight:20}}/>
                                       Send Test to Yourself
                                    </Button>
                                    {/*<Send test={true} prepare={this.props.prepare} />*/}
                                </Col>
                                <Col sm={6}>
                                    {/*<Send test={false} prepare={this.props.prepare} />*/}


                                    <Button style={{display:'flex', alignItems: 'center'}} block color={'success'} onClick={()=>{
                                        this.props.save(true);
                                        this.props.history.push('/newsletter/send/builder/confirm', {
                                            build: this.props.prepare(),
                                            test: false
                                        });
                                    }}>
                                            <i className={'lnr-users'} style={{fontSize:30, marginRight:20}}/>
                                            Send to Mailing List
                                    </Button>
                                </Col>
                            </Row>

                            <br/>
                            <p>Export Options:</p>

                            <Row>
                                <Col sm={6}>

                                    <Button block style={{display:'flex', alignItems: 'center'}} onClick={()=>{

                                        fetch('/api/newsletter/send/builder/build', {
                                            credentials: 'same-origin',
                                            method: 'post',
                                            headers: {
                                                'Content-Type': "application/json"
                                            },
                                            body: JSON.stringify({build: this.props.prepare()})
                                        })
                                            .then(res=>res.json())
                                            .then(resJSON=>{
                                                this.props.notify('success', 'Please wait, generating your PDF.');


                                                fetch('/api/newsletter/send/builder/download', {
                                                    credentials: 'same-origin',
                                                    method: 'post',
                                                    headers: {
                                                        'Content-Type': "application/json"
                                                    },
                                                    body: JSON.stringify({html: resJSON.html})})
                                                    .then(response => {
                                                        if (response.status === 200) {
                                                            this.props.notify('success', 'Your PDF newsletter has been emailed to you.');
                                                        }
                                                        else {
                                                            this.props.notify('error', 'PDF export failed, please try again later!');
                                                            return Promise.reject('PDF export failed, please try again later!');
                                                        }
                                                    })
                                            });

                                    }}>
                                        <i className={'lnr-printer'} style={{fontSize:30, marginRight:20}}/>
                                        Export as PDF
                                    </Button>

                                </Col>
                                <Col sm={6}>

                                    <OnlyWebsite prepare={this.props.prepare} />

                                </Col>
                            </Row>


                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>
        )
    }
}

