import React, {Component, Fragment} from 'react';
import {Card, CardHeader, CardBody,} from "reactstrap";

import IframeResizer from "iframe-resizer-react";
import {Loader} from "react-loaders";


export default class Knowledgebase extends Component {

    constructor(props){
        super(props);
        this.state = {
            loading: true
        };
    }

    render() {

        let url = 'https://support.kiwischools.co.nz/en/knowledgebase';

        return (
            <Fragment>
                <Card>
                    <CardHeader style={{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>

                                    <span>

                                    Knowledgebase
                                    </span>

                        <div>


                        </div>
                    </CardHeader>


                    <CardBody>

                        {this.state.loading?<div style={{display: 'flex', justifyContent: 'center', marginTop: 100}}>
                            <Loader active type={'ball-scale-multiple'}/>
                        </div>:null}

                        <IframeResizer style={{border:0, minWidth: '100%', minHeight: '100vh', marginTop: 20}} src={url} checkOrigin={false} onLoad={()=>{this.setState({loading: false})}} />

                    </CardBody>
                </Card>
            </Fragment>
        )
    }
}
