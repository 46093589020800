import {useDrop} from "react-dnd";
import React from "react";

export default function Wrapper(props){

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: 'element' ,
        drop: () => ({ name: 'element', index: props.index, addElement: props.addElement, column: props.column, addMovedElement: props.addMovedElement, parent: props.parent, isColumn:props.isColumn }),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const isActive = canDrop && isOver;
    let backgroundColor = '#fff';
    let display = 'none';
    if (isActive) {
        backgroundColor = 'lightblue';
        display = 'block';
    } else if (canDrop) {
        backgroundColor = 'lightgray';
        display = 'block';

    }

    return (

        <div
            style={{

                width:'100%',
                height: props.isColumn?12:24,
                marginTop: props.isColumn?-6:-12,
                position:'absolute',
                // backgroundColor: backgroundColor,
                opacity:'1',
                display: display,
                zIndex:props.isColumn?1000:999

            }}

            ref={drop}
        >
            <div style={{position: 'absolute', opacity: 0.4 , height: '100%', width: '100%', backgroundColor: backgroundColor}}>

            </div>

            {isActive?
                <div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
                    <div style={{height:0, width:'calc(100% + 40px)', borderTop: '3px solid #1f4694', marginLeft: -20, marginRight: -20}}> </div>

                </div>:
                null

            }

        </div>

    )
}

