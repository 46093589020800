import React, {Component, Fragment} from 'react';
import {Card, CardHeader, CardBody, Row, Col, Input, Button} from "reactstrap";

import ReactTable from "react-table";
import Editor from "./Components/Editor";



export default class List extends Component {

    constructor(props){
        super(props);
        this.state = {
            pageList: [],
            pageListOrdered: {},
            loading: true,
            pages: 0,
            data: {}
        }

        this.onInputChange = this.onInputChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.enterPressed = this.enterPressed.bind(this);
    }

    componentWillMount() {


    }

    onInputChange(event) {

        this.state.data[event.target.name] = event.target.value;
        this.setState({data: this.state.data});
    }

    enterPressed(event){
        const code = event.keyCode || event.which;
        if(code === 13) {
            this.onSearch();
        }
    }

    onSearch() {

        this.fetchData();

    }

    fetchData = ()=>{
        this.setState({loading: true});

        const url = `/api/website/wordpress/pages?page=${this.state.table_state.page}&limit=${this.state.table_state.pageSize}${this.state.data['pageSearch']?'&query=' + this.state.data['pageSearch']:''}`;

        fetch(url, {credentials: 'same-origin'})
            .then(res=>res.json())
            .then(resJSON=>{

                if (resJSON.success) {
                    this.setState({
                        pageList: resJSON.pages,
                        pages: resJSON.total_pages,
                        loading: false
                    });
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                }
            });
        fetch('/api/website/menu', {credentials: 'same-origin'})
            .then(res=>res.json())
            .then(resJSON=>{
                if (resJSON.success) {
                    this.setState({
                        pageListOrdered: resJSON.menu
                    });
                } else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                }
            });
    };

    isBlacklist(data){

        let isBlacklist = false;
        [
            'elementor',
            'table,td,th{border: none}',
            'gravityform'
        ].map((keyword) => {
            if (data.indexOf(keyword) > -1){
                isBlacklist = true;
            }
        });

        return isBlacklist;


    }



    render() {

        const Page = ({data}) => {
            return (
                <ul>
                    {Object.keys(data).map(m => {
                        let i = data[String(m)];
                        return (<li style={{ marginTop:20}}>
                            {i.title}

                            {i.page?
                            <div style={{float:'right'}}>
                                {new Date(i.page.post_modified).toLocaleDateString()} &nbsp;
                                <Editor
                                    data={i.page.post_content}
                                    title={i.page.post_title}
                                    id={i.page.id}
                                    refresh={()=>{}}
                                    isPost={false}
                                    parent={i.parent}
                                    parents={this.state.pageList}

                                /> &nbsp;
                                <a target="_blank" className={'btn btn-sm btn-secondary'} href={i.page.post_url}>View</a>
                            </div>:null}

                            {i.children && <Page data={i.children} />}
                        </li>);
                    })}
                </ul>
            );
        };


        return (
            <Fragment>
                            <Card>
                                <CardHeader style={{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>

                                    <span>
                                        Manage Pages
                                    </span>

                                    <div>

                                            <Input
                                                size={'sm'}
                                                style={{width: 300, marginRight: 10, display: 'inline-block'}}
                                                placeholder={'Type here to search'}
                                                name={'pageSearch'}
                                                onChange={this.onInputChange}
                                                onKeyPress={this.enterPressed}
                                            />

                                            <Button size={'sm'} style={{marginRight: 10}} onClick={this.onSearch}>Search</Button>


                                            <Button id={'editButtonnew'}
                                                    color="primary" size={'sm'}
                                                    onClick={()=>{

                                                        this.props.history.push('/website/pages/edit', {
                                                            data: '',
                                                            title: '',
                                                            id: 'new',
                                                            isPost: false,
                                                            create: true,
                                                            parents: this.state.pageList,
                                                            parent: {}
                                                            // refresh: this.fetchData
                                                        })

                                                    }}
                                            >
                                                New Page
                                            </Button>
                                            {/*<Editor*/}
                                            {/*    data={''}*/}
                                            {/*    title={''}*/}
                                            {/*    id={'new'}*/}
                                            {/*    isPost={false}*/}
                                            {/*    create={true}*/}
                                            {/*    refresh={this.fetchData}*/}
                                            {/*    parents={this.state.pageList}*/}
                                            {/*    parent={{}}*/}

                                            {/*/>*/}

                                            &nbsp;


                                    </div>
                                </CardHeader>


                                <CardBody>



                                    <ReactTable
                                        data={this.state.pageList}
                                        columns={[
                                            {
                                                Header: "Title",
                                                accessor: row => row.post_title,
                                                id: 'title'
                                            }, {
                                                Header: "Last Modified",
                                                accessor: row => new Date(row.post_modified).toLocaleDateString(),
                                                id: 'date'
                                            }, {
                                                Header: "Actions",
                                                Cell: ({original})=>{
                                                    return (
                                                        <div>
                                                            <Button id={'editButton' + original.id}
                                                                    disabled={this.isBlacklist(original.post_content)}
                                                                    color="primary" size={'sm'}
                                                                    onClick={()=>{

                                                                        this.props.history.push('/website/pages/edit', {
                                                                            data: original.post_content,
                                                                            title: original.post_title,
                                                                            id: original.id,
                                                                            isPost: false,
                                                                            // refresh: this.fetchData
                                                                        })

                                                                    }}
                                                                    style={this.props.create?{}:{width:70}}
                                                            >
                                                                {this.isBlacklist(original.post_content)?'Locked':"Edit"}
                                                            </Button>
                                                            {/*<Editor*/}
                                                            {/*    data={original.post_content}*/}
                                                            {/*    title={original.post_title}*/}
                                                            {/*    id={original.id}*/}
                                                            {/*    refresh={()=>{}}*/}
                                                            {/*    isPost={false}*/}
                                                            {/*/> */}

                                                            &nbsp;
                                                            <a target="_blank" className={'btn btn-sm btn-secondary'} href={original.post_url}>View</a>
                                                        </div>
                                                    )
                                                },
                                                id: 'actions'
                                            }
                                        ]}
                                        defaultPageSize={10}
                                        filterable={false}
                                        loading={this.state.loading}
                                        loadingText={'Loading pages...'}
                                        className="-striped -highlight"
                                        manual
                                        pages={this.state.pages}
                                        onFetchData={(state, instance) => {

                                            this.setState({loading: true, table_state: state}, ()=> {
                                                this.fetchData()
                                            });
                                        }}
                                    />


                                    {/*<div>*/}


                                    {/*    <Page data={this.state.pageListOrdered} />*/}


                                    {/*</div>*/}


                                </CardBody>
                            </Card>
            </Fragment>
        )
    }
}
