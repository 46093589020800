import React, {Fragment} from 'react';
import {Route, Switch} from 'react-router-dom'


import Pages from './pages';
import Posts from './posts';
import Editor from './Components/EditorPage';
import Galleries from './galleries';
import NewGallery from './Components/NewGalleryPage';
import GalleryEditor from './Components/GalleryEditorPage';
import ManageStaffList from "../App/managestafflist";
import StaffEditor from "../App/Components/StaffEditorPage";

const Website = ({match}) => (
    <Fragment>
        <Switch>
        <Route path={match.url + "/pages/edit"} component={Editor}/>
        <Route path={match.url + "/pages"} component={Pages}/>
        <Route path={match.url + "/posts/edit"} component={Editor}/>
        <Route path={match.url + "/posts"} component={Posts}/>
        <Route path={match.url + "/galleries/new"} component={NewGallery}/>
        <Route path={match.url + "/galleries/edit"} component={GalleryEditor}/>
        <Route path={match.url + "/galleries"} component={Galleries}/>
                <Route path={match.url + "/managestaff/edit"} component={StaffEditor}/>
                <Route path={match.url + "/managestaff"} component={ManageStaffList}/>
        </Switch>
    </Fragment>
);

export default Website;
// }
