import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import {withRouter, Route, Switch, Redirect} from 'react-router-dom';

import ResizeDetector from 'react-resize-detector';


import Home from './home.js';
import Hapara from '../Hapara';
import Newsletter from '../Newsletter';
import Account from '../Account';
import Support from '../Support';
import AppSection from '../App';
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import AppFooter from "../../Layout/AppFooter";
import Loader from "react-loaders";
import Website from "../Website";

import { ToastContainer } from 'react-toastify';
import InactiveProduct from "./Components/InactiveProduct";

// import steps_addUser from "../../Tutorials/Account/users";
// import steps_createPost from "../../Tutorials/Website/posts";

// import tours from '../../Tutorials/index';

import Tour from "reactour";
import Button from "reactstrap/es/Button";

// const tours = {
//
//     'account-users': steps_addUser,
//     'website-posts': steps_createPost
//
// };

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closedSmallerSidebar: false,
            user: null,
            isTourOpen: false,
            currentStep: 0,
            nav: true,
        };

        this.openTour = this.openTour.bind(this);
        this.closeTour = this.closeTour.bind(this);
        this.handleTourClick = this.handleTourClick.bind(this);

    }

    closeTour() {
        this.setState({ isTourOpen: false });
        document.removeEventListener('click', this.handleTourClick);

    };

    openTour(steps, nav) {
        this.setState({ isTourOpen: true, steps: steps, nav: true, currentStep: 0 });
        if (!nav){
            this.setState({nav:false});
            document.addEventListener('click', this.handleTourClick);
        }
    };

    handleTourClick(e){
        setTimeout(()=>{

            let isNav = this.state.steps[this.state.currentStep].nav?this.state.steps[this.state.currentStep].nav:false;

            this.setState({nav:this.state.steps[this.state.currentStep].nav, currentStep: this.state.currentStep + 1}, ()=>{
                if (!isNav){
                    document.addEventListener('click', this.handleTourClick);

                } else {
                    document.removeEventListener('click', this.handleTourClick);

                }
            });



        }, 500)
    }

    componentDidMount() {

        fetch("/api/api/user", {credentials: 'same-origin'})
            .then(res => res.json())
            .then(resJSON => {
                if (resJSON.success) {
                    this.setState({user: resJSON.user, domain: resJSON.domain});
                } else {
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                    // this.props.history.push('/login');
                }
            });

    }

    render() {

        if (!this.state.user){
            return (
                <Fragment>

                    <div
                        className="loader-wrapper d-flex justify-content-center align-items-center" style={{width:'100%',height:'100vh'}}>
                        <Loader type="ball-pulse"/>
                    </div>
                </Fragment>
            )
        }

        let {
            colorScheme,
            enableFixedHeader,
            enableFixedSidebar,
            enableFixedFooter,
            enableClosedSidebar,
            closedSmallerSidebar,
            enableMobileMenu,
            enablePageTabsAlt,
        } = this.props;

        return (
            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <Fragment>
                        <div className={cx(
                            "app-container app-theme-" + colorScheme,
                            {'fixed-header': enableFixedHeader},
                            {'fixed-sidebar': enableFixedSidebar || width < 1250},
                            {'fixed-footer': enableFixedFooter},
                            {'closed-sidebar': enableClosedSidebar || width < 1250},
                            {'closed-sidebar-mobile': closedSmallerSidebar || width < 1250},
                            {'sidebar-mobile-open': enableMobileMenu},
                            {'body-tabs-shadow-btn': enablePageTabsAlt},
                        )}>
                            <AppHeader user={this.state.user} {...this.props}/>
                            <div className="app-main">
                                <AppSidebar history={this.props.history}/>
                                <div className="app-main__outer">
                                    <div className="app-main__inner">
                                        <Switch>
                                            <Route exact path="/" render={() => (
                                                <Redirect to="/home"/>
                                            )}/>
                                        <Route path="/home" component={Home}/>
                                        <Route path="/hapara" render={(props) => (
                                            this.state.domain.hapara
                                                ? <Hapara {...props} />
                                                : <InactiveProduct/>
                                        )}/>
                                        <Route path="/newsletter" render={(props) => (
                                            this.state.domain.newsletter
                                                ? <Newsletter {...props} account={this.state.domain} />
                                                : <InactiveProduct/>
                                        )}/>
                                        <Route path="/website"  render={(props) => (
                                            this.state.domain.website
                                                ? <Website {...props} />
                                                : <InactiveProduct/>
                                        )}/>
                                        <Route path="/app"  render={(props) => (
                                            this.state.domain.app
                                                ? <AppSection {...props} />
                                                : <InactiveProduct/>
                                        )}/>
                                        <Route path="/account" render={(props) => (
                                                <Account {...props} account={this.state.domain} />
                                        )}/>
                                        <Route path="/support" render={(props) => (
                                                <Support {...props} openTour={this.openTour} />
                                        )}/>
                                        </Switch>

                                    </div>
                                    <AppFooter/>

                                </div>
                            </div>
                        </div>
                        <ToastContainer style={{marginTop: 60}}/>
                        <Tour
                            onRequestClose={this.closeTour}
                            steps={this.state.steps}
                            isOpen={this.state.isTourOpen}
                            maskClassName="mask"
                            className="helper"
                            rounded={5}
                            accentColor={'#3f6ad8'}
                            startAt={0}
                            goToStep={this.state.currentStep}
                            disableDotsNavigation={true}
                            showButtons={this.state.nav}
                            // closeWithMask={true}
                        />

                    </Fragment>
                )}
            />
        )
    }
}

const mapStateToProp = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,

});

export default withRouter(connect(mapStateToProp)(Main));
