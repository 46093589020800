import React, {Fragment} from 'react';
import {
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    Row,
    Col,
    Container,
    CardHeader, CardBody, Card
} from 'reactstrap';
import Input from "reactstrap/es/Input";
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import {toast} from "react-toastify";
import BlockUi from "react-block-ui";
import {Loader} from "react-loaders";
import { Editor as ContentEditor } from '@tinymce/tinymce-react';

// import Modal from 'react-bootstrap/Modal';
import Modal from 'react-modal';




export default class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            data: "",
            title: "",
            loading: false,
            galleryDropdown: false,
            parentDropdown: false,
            parent: {},
            galleries: [],
            categories: [],
            category: {}
        };

        this.toggle = this.toggle.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onInsertAttachment = this.onInsertAttachment.bind(this);
        this.onTitleChange = this.onTitleChange.bind(this);
    }
        toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    isBlacklist(){

        let isBlacklist = false;
        [
            'elementor',
            'table,td,th{border: none}',
            'gravityform'
        ].map((keyword) => {
            if (this.state.data.indexOf(keyword) > -1){
                isBlacklist = true;
            }
        });

        return isBlacklist;


    }

    onTitleChange(e){
        this.setState({title: e.target.value});
    }

    onSave(){
        this.setState({loading: true});

        let url = '/api/website/pages/update';
        if (this.props.location.state.isPost){
            url = '/api/website/posts/update';
        }

        if (this.props.location.state.create){
            url = url.replace('update', 'create')
        }
        fetch(url,{
            credentials: 'same-origin',
            method: 'post',
            headers: {
                'Content-Type': "application/json"
            },
            body: JSON.stringify({id: this.props.location.state.id, title: this.state.title, content: this.state.data, category: this.state.category, parent: this.state.parent.title?this.state.parent.title:null})
        })
            .then(res => res.json())
            .then(resJSON =>{
                this.setState({loading: false});

                if (resJSON.success){
                    this.props.history.push(`/website/${this.props.location.state.isPost?'posts':'pages'}`);
                    // window.location.reload();


                    if (this.props.location.state.create){

                        if (this.props.location.state.isPost){
                            toast['success']("Success! Post has been published.");
                        } else {
                            toast['success']("Success! Page will be published within 24 hours.");
                        }

                    } else {

                        if (this.props.location.state.isPost){
                            toast['success']("Success! Post has been updated.");
                        } else {
                            toast['success']("Success! Page has been updated");
                        }

                    }
                    //
                    // this.props.location.state.refresh();
                }else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    toast['error']("Error! Please try again.");

                }
            })
    }

    onInsertAttachment(e){
        let formData = new FormData();
        formData.append('file', e.target.files[0]);

        this.setState({loading: true});

        fetch('/api/website/media/upload', {
            credentials: 'same-origin',
            method: 'post',
            body: formData
        })
            .then(res => res.json())
            .then(resJSON=>{

                this.setState({loading: false});
                if (resJSON.success){

                    window.tinymce.activeEditor.execCommand('mceInsertContent', false, `<a href="${resJSON.media.source_url}">${resJSON.media.title.rendered?resJSON.media.title.rendered:'Attachment'}</a>`);

                }else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.notify('error', <span><strong>Failed!</strong> Please try again!</span>)

                }

            })
    }

    componentWillMount() {

        if (!this.props.location.state) {
            return this.props.history.push('/website/posts');
        }

        this.setState({
            data: this.props.location.state.data,
            title: this.props.location.state.title,
            parent: this.props.location.state.parent?this.props.location.state.parent:{}
        });

        fetch('/api/website/wordpress/galleries/min', {
            credentials: 'same-origin',
        })
            .then(res => res.json())
            .then(resJSON=>{

                this.setState({loading: false});
                if (resJSON.success){

                    this.setState({galleries: resJSON.galleries})
                }else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.notify('error', <span><strong>Failed!</strong> Couldn't load galleries</span>)
                }

            });


        fetch('/api/website/wordpress/categories', {
            credentials: 'same-origin',
        })
            .then(res => res.json())
            .then(resJSON=>{

                this.setState({loading: false});
                if (resJSON.success){
                    this.setState({categories: resJSON.categories});

                    if (this.props.location.state.category){
                        this.props.location.state.category.split(', ').forEach((category)=>{
                            this.state.categories.forEach((actualCat)=>{
                                if (actualCat.name === category){
                                    this.setState({
                                        category: {...this.state.category, [actualCat.id]: category}
                                    })
                                }
                            })
                        })

                    }

                    if (this.props.location.state.create && this.props.location.state.isPost){
                        resJSON.categories.forEach((item)=>{
                            if (item.name === "Newsflash"){
                                this.setState({
                                    category: {...this.state.category, [String(item.id)]: item.name}
                                });
                            }
                        })
                    }

                }else if (resJSON['isAuthFailed']){
                    if (process.env.NODE_ENV === 'development'){
                        window.location.href = 'http://localhost:3001/api/login';
                    } else {
                        window.location.href = 'https://central.kiwischools.co.nz/api/login';
                    }
                } else {
                    this.notify('error', <span><strong>Failed!</strong> Couldn't load categories</span>)
                }

            })

    }

    render() {



        return (
            <Fragment>

                {/*<Button id={'editButton' + this.props.location.state.id}*/}
                {/*        disabled={this.isBlacklist()}*/}
                {/*        color="primary" size={'sm'}*/}
                {/*        onClick={this.toggle}*/}
                {/*        style={this.props.location.state.create?{}:{width:70}}*/}
                {/*>*/}
                {/*    {this.props.location.state.create?'Add New':this.isBlacklist()?'Locked':"Edit"}*/}
                {/*</Button>*/}

                {/*<Modal ariaHideApp={false} enforceFocus={false} isOpen={this.state.modal} onRequestClose={this.toggle} className={this.props.location.state.className} size="xl">*/}

                <Card>

                    <CardHeader style={{display:'flex', alignItems: 'center',justifyContent: 'space-between'}}>

                                    <span>
                                        {this.props.location.state.create?(this.props.location.state.isPost?'Create Post':'Create Page'):((this.props.location.state.isPost?'Edit Post':'Edit Page'))}
                                    </span>
                    </CardHeader>

                    <CardBody>
                    <BlockUi tag="div" blocking={this.state.loading}  style={{overflow:'hidden'}} loader={<Loader color="#ffffff" active type={'ball-clip-rotate'}/>}>

                    <div>
                        <Row>
                            <Col xs={8}>
                        <FormGroup>
                            <Label for={'title'}>Title</Label>
                            <Input name={'title'} value={this.state.title} onChange={this.onTitleChange}/>
                        </FormGroup>


                                <Label for={'title'}>Content</Label>

                             <FormGroup >

                                 <div>
                            <Input type="file" name="file" id="file" value={this.state.attachment} onChange={this.onInsertAttachment} style={{display: 'none'}}/> &nbsp;
                                     <Button style={{float: 'left'}} onClick={()=>{document.getElementById('file').click()}}>Insert Attachment</Button>

                                     {this.props.location.state.isPost ? null :
                                         <Dropdown style={{float: 'left'}} isOpen={this.state.galleryDropdown}
                                                   toggle={() => {
                                                       this.setState({galleryDropdown: !this.state.galleryDropdown})
                                                   }}>
                                             <DropdownToggle caret>
                                                 Insert Gallery
                                             </DropdownToggle>
                                             <DropdownMenu

                                                 modifiers={{
                                                     setMaxHeight: {
                                                         enabled: true,
                                                         order: 890,
                                                         fn: (data) => {
                                                             return {
                                                                 ...data,
                                                                 styles: {
                                                                     ...data.styles,
                                                                     overflow: 'auto',
                                                                     maxHeight: 200,
                                                                 },
                                                             };
                                                         },
                                                     },
                                                 }}
                                             >
                                                 {this.state.galleries.map((item) => {
                                                     return <DropdownItem
                                                         onClick={() => {

                                                             window.tinymce.activeEditor.execCommand('mceInsertContent', false, `[envira-slider id="${item.id}"]`);

                                                     }}>{item.post_title}</DropdownItem>
                                                 })}
                                             </DropdownMenu>
                                         </Dropdown>
                                     }

                                 </div>
                            </FormGroup>
                            </Col>

                            {
                                this.props.location.state.isPost ?

                            <Col xs={4}>
                                <Label for={'title'}>Category</Label>
                                {this.state.categories.map((item) => (
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" onChange={()=>{
                                                if (Object.keys(this.state.category).indexOf(String(item.id)) > -1){

                                                    let old_category = this.state.category;
                                                    delete old_category[String(item.id)];
                                                    this.setState({
                                                        category: old_category
                                                    });
                                                } else {
                                                    this.setState({
                                                        category: {...this.state.category, [String(item.id)]: item.name}
                                                    });
                                                }

                                            }}
                                                   checked={Object.keys(this.state.category).indexOf(String(item.id)) > -1}/> {item.name}
                                        </Label>
                                    </FormGroup>
                                ))}


                            </Col> : <Col xs={4}>
                                        {!this.props.location.state.isPost && this.props.location.state.parents && this.props.location.state.create ?
                                        <FormGroup>
                                            <Label for={'title'}>Menu Parent</Label><br/>



                                                <Dropdown style={{float: 'left'}} isOpen={this.state.parentDropdown}
                                                          toggle={() => {
                                                              this.setState({parentDropdown: !this.state.parentDropdown})
                                                          }}>
                                                    <DropdownToggle caret>
                                                        {this.state.parent.id?this.state.parent.title:"Top Level"}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {this.props.location.state.parents.map((item) => {
                                                            return <DropdownItem onClick={() => {this.setState({parent: {id: item.id, title: item.post_title}})
                                                            }}>{item.post_title}</DropdownItem>
                                                        })}
                                                    </DropdownMenu>
                                                </Dropdown>
                                        </FormGroup>: null
                                        }
                                    </Col>
                                            }


                        </Row>

                        <FormGroup>

                            <br/>

                            <ContentEditor

                                onEditorChange={(content, editor)=>{

                                    this.setState({data: content});
                                }}
                                value={this.state.data}
                                init={{
                                    'toolbar': 'fontselect fontsizeselect forecolor backcolor | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist table | link image | undo redo',
                                    'plugins': 'lists table link image paste',
                                    menubar:false,
                                    contextmenu: false,
                                    images_upload_url: '/api/website/media/upload/builder',
                                    image_class_list: [
                                        {title: 'Responsive', value: 'img-responsive'}
                                    ],
                                    height:300,
                                    relative_urls : false,
                                    remove_script_host : false,
                                    paste_auto_cleanup_on_paste : false
                                }}
                            />
                        </FormGroup>
                    </div>
                    <div>
                        <Button color="link" onClick={()=>this.props.history.push(`/website/${this.props.location.state.isPost?'posts':'pages'}`)}>Cancel</Button>
                            <Button color="primary" onClick={this.onSave}>{this.props.location.state.create?'Create':'Save'}</Button>
                    </div>
                    </BlockUi>
                    </CardBody>
                </Card>


                {/*</Modal>*/}
            </Fragment>
        );
    }
}
